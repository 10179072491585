import React, { useContext, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import { ECNav } from "ec-commons";
import Web3Ctx from "../../components/Context/Web3Ctx";
import {
  useDustDispenserContract,
  useDustDispenserV2Contract,
  useDustExtraAllocatorContract,
  useDustTokenContract,
} from "../../hooks/useContract";

import CHAIN_INFO from "../../components/Web3Manager/Constants";
import config from "../../config";
import { toast } from "react-toast";
import { useHistory } from "react-router";
import queryString from "query-string";
import { formatDustAmount, parseDustAmount } from "../../components/Utils";

import { SpinnerDotted } from "spinners-react";
import { DustAmount, DustAmountClaimButton } from "./components";
import { Container as BootStrapContainer } from "styled-bootstrap-grid";
import { Row, RowJustify } from "../../components/Row";
import { ColAlign, Col } from "../../components/Col";
import { PinkOutline, ButtonPink } from "../../components/Button";
import { LinkPink } from "../../components/Link";

import DustWalletImg from "../../assets/images/dust-wallet.png";
import DustCheckImg from "../../assets/images/dust-check.png";
import { media } from "styled-bootstrap-grid";

import {
  FloatingCardCoins,
  SpinningDustHexagon,
} from "../../components/Animation";

import { BitArray } from "@ethercards/ec-util";
import { ethers } from "ethers";
import { SupportedChainId } from "../../abi/constants/chain";

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled(BootStrapContainer)`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 100px;
`;

const Title = styled.h2`
  color: white;
  font-family: "Poppins";
  text-align: center;
  margin-bottom: 20px;
  margin-top: 0;
`;

const Text = styled.p`
  font-family: "Poppins";
  color: #9a989a;
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 400;
`;

const Image = styled.img`
  max-width: 500px;
  align-self: center;
`;

const Image2 = styled.img`
  width: 300px;
  max-width: 300px;
  align-self: center;
`;

const ImageOverlay = styled.img`
  position: absolute;
  width: 300px;
  top: 135px;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  animation: ${fadeInAnimation} 2s linear 0.5s 1 normal forwards;
`;

const FullScreenLoader = styled.div`
  display: ${({ show }) => (show ? "visible" : "none")};
  z-index: 2000;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  padding-top: 10%;
`;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.2rem;
  color: grey;
  background: #0b122954;
  border-radius: 10px;
  padding: 7px 10px;
  margin: 5px 0;
  text-align: left;
`;

const DustText = styled.div`
  align-self: center;
`;
const AmountText = styled.span`
  color: white;
  font-weight: 500;
`;

const SmallSpacer = styled.div`
  ${media.xs`
    margin-right: 20px;
  `}
`;

const DustWithdrawalPage = (props) => {
  const {
    onboard,
    handleConnect,
    address,
    chainId,
    defaultChainId,
    switchNetwork,
  } = useContext(Web3Ctx);

  const [showLoader, setShowLoader] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [txCompleted, setTxCompleted] = useState(false);
  const [txEtherScan, setTxEtherScan] = useState(null);

  const [dustAmount, setDustAmount] = useState(0);
  const [dustExtraAmount, setDustExtraAmount] = useState(0);
  const [dustExtra2Amount, setDustExtra2Amount] = useState(0);

  const [tokenId, setTokenId] = useState(null);
  const [tokenIds, setTokenIds] = useState(null);

  const [walletDustAmount, setWalletDustAmount] = useState(0);
  const [partialClaimIds, setPartialClaimIds] = useState(null);
  const [claimContract, setClaimContract] = useState(null);
  // const [claimAmount, setClaimAmount] = useState(0);
  const [partialDustAmount, setPartialDustAmount] = useState(0);
  const [dustExtraLocked, setDustExtraLocked] = useState(false);

  const history = useHistory();

  const dustContract = useDustDispenserContract();
  const dustExtraContract = useDustExtraAllocatorContract();
  const dustExtra2Contract = useDustDispenserV2Contract();
  const dustExtra2ContractChainId =
    dustExtra2Contract?.provider?._network?.chainId;
  const dustTokenContract = useDustTokenContract();

  useEffect(() => {
    if (dustContract && dustExtraContract && dustExtra2Contract) {
      checkContractLocked(dustExtraContract, setDustExtraLocked);
      const claimIds = tokenId ? [tokenId] : tokenIds ? tokenIds : [];
      if (claimIds && claimIds.length > 0) {
        fetchAllDustAmount(claimIds);
      }
    }
  }, [dustContract, dustExtraContract, dustExtra2Contract, tokenIds, tokenId]);

  useEffect(() => {
    if (claimContract && partialClaimIds && partialClaimIds.length > 0) {
      fetchPartialDustAmount(partialClaimIds);
    }
  }, [partialClaimIds, claimContract]);

  // useEffect(() => {
  //   if (!address) {
  //     history.push("/wallet");
  //   }
  // }, [address]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!address) {
      history.push("/wallet");
    }

    const { tokenId, tokenIds } = props.location.search
      ? queryString.parse(props.location.search)
      : props.location;
    if (tokenId) {
      setTokenId(Number(tokenId));
    } else if (tokenIds) {
      setTokenIds(tokenIds);
    } else {
      history.push("/wallet");
    }
  }, []);

  const fetchWalletDustAmount = async () => {
    const dust = await dustTokenContract["balanceOf"](address);
    if (dust) {
      setWalletDustAmount(formatDustAmount(dust));
    }
  };

  const fetchPartialDustAmount = async (partialIds) => {
    setShowSpinner(true);
    const filteredIds = partialIds.filter((tokenId) => Number(tokenId) >= 10);
    const totalDustAmount = await claimContract[
      "getAvailableBalance(uint16[])"
    ](filteredIds);
    if (totalDustAmount) {
      setPartialDustAmount(formatDustAmount(totalDustAmount));
    }
    setShowSpinner(false);
  };

  const fetchAllDustAmount = async (tokenIds) => {
    setShowSpinner(true);
    const filteredIds = tokenIds.filter((tokenId) => Number(tokenId) >= 10);
    const totalDustAmount = await dustContract["getAvailableBalance(uint16[])"](
      filteredIds
    );
    if (totalDustAmount) {
      setDustAmount(formatDustAmount(totalDustAmount));
    }

    const totalDustExtraAmount = await dustExtraContract[
      "getAvailableBalance(uint16[])"
    ](filteredIds);

    if (totalDustExtraAmount) {
      setDustExtraAmount(formatDustAmount(totalDustExtraAmount));
    }

    const totalDustExtra2Amount = await dustExtra2Contract[
      "getAvailableBalance(uint16[])"
    ](filteredIds);

    if (totalDustExtra2Amount) {
      setDustExtra2Amount(formatDustAmount(totalDustExtra2Amount));
    }
    setShowSpinner(false);
  };

  const checkContractLocked = async (claimContract, setClaimLocked) => {
    const locked = await claimContract["locked"]();
    console.log("contract locked:", locked);
    if (locked) {
      setClaimLocked(true);
    } else {
      const unlockTime = await claimContract["unlockTime"]();
      const currentTime = (Date.now() / 1000) | 0;
      console.log("unlock time:", Number(unlockTime));
      setClaimLocked(Number(unlockTime) > currentTime);
    }
  };

  const getUsedTokenIds = async (claimContract) => {
    return claimContract.getUsedTokenData(0, 10000).then((usedTokenData) => {
      const usedIds = BitArray.fromUint8Array(usedTokenData);
      return Object.keys(usedIds.toEnabled()).map((id) => Number(id));
    });
  };

  const handleDustDropClaim = (contract) => {
    if (chainId != defaultChainId) {
      switchNetwork(defaultChainId);
    } else {
      withdrawDust(contract);
    }
  };

  const dustDropBtnText = chainId != defaultChainId ? "Switch Network" : null;
  const dustTrickleBtnText =
    chainId != dustExtra2ContractChainId ? "Switch Network" : null;

  // console.log(dustExtra2ContractChainId);

  const handleDustTrickleClaim = () => {
    if (chainId != dustExtra2ContractChainId) {
      switchNetwork(SupportedChainId.POLYGON);
    } else {
      withdrawDust(dustExtra2Contract, false);
    }
  };

  // No need to check usedIds for dust trickling contract
  const withdrawDust = async (claimContract, checkUsedIds = true) => {
    console.log("claiming dust");
    setClaimContract(claimContract);

    if (showLoader || txInProgress || !claimContract) return;

    setShowLoader(true);

    const claimIds = tokenId ? [tokenId] : tokenIds ? tokenIds : [];
    let usedIds = [];
    if (checkUsedIds) {
      usedIds = await getUsedTokenIds(claimContract);
    }
    const filteredIds = claimIds.filter((id) => !usedIds.includes(id));

    if (filteredIds.length == 0) {
      toast.error(`Don't have eligible Id for claiming`);
      setShowLoader(false);
      return;
    }

    // Limited to 400 Ids for claiming
    if (filteredIds.length > 400) {
      setPartialClaimIds(filteredIds.slice(0, 400));
      setShowLoader(false);
      return;
    }

    await withdrawNow(claimContract, filteredIds);

    setShowLoader(false);
  };

  const withdrawNow = async (claimContract, tokenIds) => {
    // console.log(claimContract);

    return claimContract
      .redeem(tokenIds)
      .then((tx) => {
        setTxEtherScan(
          `${CHAIN_INFO[chainId].BLOCK_EXPLORER_URLS}/tx/` + tx.hash
        );
        setShowLoader(false);
        setTxInProgress(true);
        window.scrollTo(0, 0);

        return tx.wait().then(async (receipt) => {
          console.log("txReceipt: ", receipt);
          if (receipt && receipt.status === 1) {
            await fetchWalletDustAmount();
            toast.success("Successfully claimed Dust");
            setTxEtherScan(null);
            setTxCompleted(true);
          } else {
            toast.error("Transaction Failed");
            setTxEtherScan(null);
            setTxInProgress(false);
          }
        });
      })
      .catch(handleError);
  };

  const handleError = (e) => {
    if (e.error && e.error.message) {
      toast.error(e.error.message);
    } else if (e.message) {
      toast.error(e.message);
    } else if (e.reason) {
      toast.error(e.reason);
    }
    console.error(e);
    setShowLoader(false);
  };

  return (
    <>
      <ECNav
        projectUrl={config.APP_BASE_URL}
        onboard={onboard}
        address={address}
      />

      <FullScreenLoader show={showLoader} />

      <Container className="container">
        {!txInProgress && !txCompleted && (
          <>
            <FloatingCardCoins></FloatingCardCoins>
            <Title>
              {tokenId
                ? `Claim Dust of Card #${tokenId}`
                : `Claim all your Dust`}
            </Title>

            {showSpinner && (
              <Row>
                <ColAlign align="center">
                  <SpinnerDotted
                    size={30}
                    thickness={160}
                    speed={100}
                    color="rgba(255, 255, 255, 0.5)"
                  ></SpinnerDotted>
                </ColAlign>
              </Row>
            )}

            {!address && (
              <>
                <Row>
                  <ColAlign
                    col
                    md={8}
                    align="center"
                    style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}
                  >
                    <Text>
                      In order to claim your dusts, you need to connect your
                      wallet
                    </Text>
                  </ColAlign>
                </Row>

                <RowJustify justify="center">
                  <PinkOutline onClick={handleConnect}>CONNECT</PinkOutline>
                </RowJustify>
              </>
            )}

            {address && !partialClaimIds && (
              <>
                <ColAlign col={12} md={12} lg={9} align="center">
                  <DustAmountClaimButton
                    amount={dustAmount}
                    text={"First Dust Drop"}
                    buttonText={dustDropBtnText}
                    onClick={() => {
                      handleDustDropClaim(dustContract);
                    }}
                  />
                  <DustAmountClaimButton
                    claimLocked={dustExtraLocked}
                    amount={dustExtraAmount}
                    text={"Second Dust Drop"}
                    buttonText={dustDropBtnText}
                    onClick={() => {
                      handleDustDropClaim(dustExtraContract);
                    }}
                  />
                  <DustAmountClaimButton
                    amount={dustExtra2Amount}
                    text={"Dust Trickling"}
                    buttonText={dustTrickleBtnText}
                    onClick={() => {
                      handleDustTrickleClaim();
                    }}
                  />
                </ColAlign>

                <Row>
                  <ColAlign
                    col
                    md={8}
                    align="center"
                    style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}
                  >
                    {chainId != defaultChainId && (
                      <Text>
                        Dust Drops are only claimable on{" "}
                        <span className="text-white">
                          {CHAIN_INFO[defaultChainId].CHAIN_NAME}
                        </span>
                        . Please switch network to claim.
                      </Text>
                    )}
                    {dustExtra2ContractChainId &&
                      chainId != dustExtra2ContractChainId && (
                        <Text>
                          Dust Trickling is only claimable on{" "}
                          <span className="text-white">
                            {CHAIN_INFO[dustExtra2ContractChainId].CHAIN_NAME}
                          </span>
                          . Please switch network to claim.
                        </Text>
                      )}
                    <Text>
                      Each Ether Cards Dust drop has its own unique smart
                      contract. As such, users can claim from one drop (smart
                      contract) at a time. Each drop claim will be processed as
                      a separate transaction.
                    </Text>
                  </ColAlign>
                </Row>

                <RowJustify justify="center">
                  <PinkOutline
                    as={Link}
                    to="/wallet"
                    // style={{ marginTop: "30px" }}
                  >
                    Cancel
                  </PinkOutline>
                </RowJustify>
              </>
            )}

            {/* {!showSpinner && !partialClaimIds && claimContract && (
              <>
                <DustAmount
                  amount={claimAmount}
                  text={tokenId && `Dust to be claimed`}
                />
                <Row>
                  <ColAlign
                    col
                    md={8}
                    align="center"
                    style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}
                  >
                    <Text>
                      {tokenId
                        ? `You are about to claim the Dust stored on your card. If you do not wish to do this, please click the “Back” button.`
                        : "You are about to claim all the Dust stored on the cards in your wallet in one transaction. If you do not wish to do this, please click the “Back” button."}
                    </Text>
                  </ColAlign>
                </Row>

                <Row>
                  <ColAlign col={6} align="right">
                    <PinkOutline onClick={() => setClaimContract(null)}>
                      Back
                    </PinkOutline>
                  </ColAlign>

                  <Col col={6}>
                    <ButtonPink onClick={withdrawDust}>
                      Claim {`${tokenId ? "Dust" : "All"}`}
                    </ButtonPink>
                  </Col>
                </Row>
              </>
            )} */}

            {address && !showSpinner && partialClaimIds && claimContract && (
              <>
                <DustAmount
                  amount={partialDustAmount}
                  text={`Total Dust to be claimed with this transaction`}
                />
                <Row>
                  <ColAlign
                    col
                    md={8}
                    align="center"
                    style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}
                  >
                    <Text>
                      Due to the large amount of cards, you are required to
                      complete multiple transactions to fully claim all your
                      dust. The claimable dust amount for this transaction is
                      shown as above. If you do not wish to do this, please
                      click the “Back” button.
                    </Text>
                  </ColAlign>
                </Row>

                <Row>
                  <ColAlign col={6} align="right">
                    <PinkOutline
                      onClick={() => {
                        setPartialClaimIds(null);
                        setClaimContract(null);
                      }}
                    >
                      Back
                    </PinkOutline>
                  </ColAlign>

                  <Col col={6}>
                    <ButtonPink
                      onClick={() =>
                        withdrawNow(claimContract, partialClaimIds)
                      }
                    >
                      Claim Dust
                    </ButtonPink>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}

        {txInProgress && !txCompleted && (
          <>
            <SpinningDustHexagon
              height={100}
              style={{ marginTop: "50px", marginBottom: "50px" }}
            />
            <Title>Waiting for transaction</Title>
            <Row>
              <ColAlign
                col
                md={8}
                align="center"
                style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}
              >
                <Text>
                  We’re brushing the Dust off your cards now. You can check the
                  transaction status on{" "}
                  <LinkPink href={txEtherScan} target="_blank">
                    Etherscan.
                  </LinkPink>
                </Text>
              </ColAlign>
            </Row>
          </>
        )}

        {txCompleted && (
          <>
            <Image2 src={DustWalletImg}></Image2>
            <ImageOverlay src={DustCheckImg}></ImageOverlay>
            <Title style={{ marginTop: "50px" }}>
              Transaction was successful
            </Title>
            <Row>
              <ColAlign
                col
                md={10}
                lg={8}
                align="center"
                style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}
              >
                <DustAmountClaimButton
                  amount={walletDustAmount || "0"}
                  text={"Total Dust in your wallet"}
                  buttonText={"Back to wallet"}
                  onClick={() => {
                    history.push("/wallet");
                  }}
                />
              </ColAlign>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default DustWithdrawalPage;
