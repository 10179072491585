import React, { useEffect, useRef, useState, useContext } from 'react';
import RemoteImage from './RemoteImage';
import { getTokenURI, validateUrl } from '../Utils';
import useContainerDimensions from './useContainerDimensions';
import './NftCardV2.css';
import './EmbeddedCard.css';
import Web3Ctx from '../Context/Web3Ctx';
import config from '../../config';
import defImage from '../../assets/images/default-card-image.jpg';
import defaultImageOg from '../../assets/images/default-card-image-OG.jpg';
import defaultImageAlpha from '../../assets/images/default-card-image-ALPHA.jpg';
import defaultImageCommon from '../../assets/images/default-card-image-COMMON.jpg';
import ecLogo from '../../assets/images/logo-in-menu.png';
import closeX from '../../assets/images/nft-icons/close_button.svg';
import sensitiveContent from '../../assets/images/sensitive.png';
import fsIcon from '../../assets/images/fs.png';
import switchIcon from '../../assets/images/switch.png';
import layerIcon from '../../assets/images/icon_layer-explorer.png';
import phoenixIcon from '../../assets/images/traits/icon-phoenix.png'

import PLACEHOLDER from '../../assets/images/traits/small_rplaceholder_icon.png';
import LayeredImageViewer from './LayeredImageViewer';
import styled from 'styled-components';
import { DustCardEmbedPopup, DustAmountEmbedButton } from '../../pages/Dust/components';
import { GiftCardEmbedPopup as GiftEmbedPopup, DustAmountEmbedButton as GiftEmbedButton } from '../../pages/Gift/components';
import { EmbedButtonIcon } from '../Button';
import { EmbedPopup } from '../Embed';

const StyledEmbedButtonIcon = styled(EmbedButtonIcon)`
   display: ${({hasTrait}) => (hasTrait? "block": "none")};
   position: relative;
   min-height: 2.2em;
   opacity: ${({ showButton }) => (showButton ? 1 : 0)};
   transition: all 0.4s ease-in-out;
   margin-right: 3%;
   border-radius:0.6em;
   pointer-events: ${({ showButton }) => (!showButton && "none")};
`
const StyledDustAmountButton = styled(DustAmountEmbedButton)`
  position: relative;
  min-height: 2.2em;
  opacity: ${({ showButton }) => (showButton ? 1 : 0)};
  transition: all 0.4s ease-in-out;
  margin-right: 3%;
  border-radius:0.6em;
  pointer-events: ${({ showButton }) => (!showButton && "none")};
`;

const StyledGiftButton = styled(GiftEmbedButton)`
  position: relative;
  min-height: 2.2em;
  opacity: ${({ showButton }) => (showButton ? 1 : 0)};
  transition: all 0.4s ease-in-out;
  margin-right: 3%;
  border-radius:0.6em;
  pointer-events: ${({ showButton }) => (!showButton && "none")};
`;

const EmbeddedCard = (props) => {

   const containerRef = useRef()
   const { width, height } = useContainerDimensions(containerRef);

   //enum CardType { OG, Alpha, Random, Common, Founder,  Unresolved } 

   const cardTypes = [
      { type: 'OG', cName: 'og', defImg: defaultImageOg },
      { type: 'ALPHA', cName: 'alpha', defImg: defaultImageAlpha },
      { type: 'RANDOM', cName: 'common', defImg: defImage },
      { type: 'FOUNDER', cName: 'common', defImg: defaultImageCommon },
      { type: 'CREATOR', cName: 'founder', defImg: defaultImageOg },
      { type: 'UNRESOLVED', cName: 'unresolved', defImg: defImage }
   ];

   // //TRAIT
   // const traitImages = {
   //    0: ogLayerDrop,
   //    1: alphaLayerDrop,
   //    2: limitedSlot,
   //    3: battlePunk,
   //    4: battleRoyalty,
   //    5: supporter
   // }

   const { wallet } = useContext(Web3Ctx);

   const [contract, setContract] = useState({ address: '' });
   const [provider, setProvider] = useState({ network: { name: '' } });
   const [tokenId, setTokenId] = useState(null);
   const [tokenURI, setTokenURI] = useState('');
   const [serialNr, setSerialNr] = useState(0);
   const [resolved, setResolved] = useState(false);
   const [saleActive, setSaleActive] = useState(true);
   const [initialised, setInitialised] = useState(false);
   const [cardType, setCardType] = useState(5);

   const [traitsOfToken, setTraitsOfToken] = useState([]);

   const [traitsVisible, setTraitsVisible] = useState(false);
   const [detailsVisible, setDetailsVisible] = useState(false);
   const [dustVisible, setDustVisible] = useState(false);
   const [giftVisible, setGiftVisible] = useState(false);
   const [giftButtonVisible, setGiftButtonVisible] = useState(false);
   const [phoenixVisible, setPhoenixVisible] = useState(false);
   const [phoenixTrait, setPhoenixTrait] = useState(null);

   const [selectedTraitIdx, setSelectedTraitIdx] = useState(null);
   const [selectedTraitIdxDetails, setSelectedTraitIdxDetails] = useState(0);

   const [isVideoOn, setIsVideoOn] = useState(false);
   const [tokenImage, setTokenImage] = useState('');
   const [originalImage, setOriginalImage] = useState('');
   const [visibleImage, setVisibleImage] = useState('');
   const [layeredImage, setLayeredImage] = useState('');
   const [defaultImage, setDefaultImage] = useState(defImage);
   const [isSensitive, setIsSensitive] = useState(false);
   const [videoToFill, setVideoToFill] = useState(null);
   const [tokenVideo, setTokenVideo] = useState('');
   const [hasRealImage, setHasRealImage] = useState(false);
   const [artTitle, setArtTitle] = useState('Untitled');
   const [artist, setArtist] = useState('');
   const [layerArtists, setLayerArtists] = useState([]);

   const [containerSize, setContainerSize] = useState('medium');
   const [jsonUri, setJsonUri] = useState('');
   const [isMainActive, setIsMainActive] = useState(true);

   const [isFlipped, setIsFlipped] = useState(false);

   const [dustAmount, setDustAmount] = useState("0");

   const popupVisible = traitsVisible || detailsVisible || dustVisible || giftVisible || phoenixVisible;

   useEffect(() => {
      if (width) {
         let cSize = '';
         if (width > 500) {
            cSize = 'c-xlarge';
         } else if (width > 400) {
            cSize = 'c-large';
         } else if (width > 300) {
            cSize = 'c-medium';
         } else if (width > 240) {
            cSize = 'c-small';
         } else {
            cSize = 'c-xsmall'
         }
         setContainerSize(cSize);
         // console.log(containerSize)
      }
   }, [width])

   //TRAITS
   const setupMeta = (tokenMeta) => {
      if (!tokenMeta) {
         console.log('-No metadata');
         return;
      }

      setIsFlipped(false);


      if (tokenMeta.image) {
         //B&V overlay
         setIsSensitive(getFlagFromProperty(tokenMeta.properties, 'sensitive'));
         setVideoToFill(getFlagFromProperty(tokenMeta.properties, 'fill'));

         //set the previous token image as bg image 
         if (tokenImage !== '') {
            setDefaultImage(validateUrl(tokenImage));
         }

         setArtist(tokenMeta.artist);
         setLayerArtists(tokenMeta.layer_artists ? tokenMeta.layer_artists : []);
         setArtTitle(tokenMeta.title ? tokenMeta.title : 'Accidental Collaboration');

         //main image
         let theTokenImage = tokenMeta.image;
         if (theTokenImage.indexOf('/ec_og.') === -1 && theTokenImage.indexOf('/ec_alpha.') === -1 && theTokenImage.indexOf('/ec_common.') === -1) {
            setHasRealImage(true);
            if (tokenMeta.layer_image) {
               setLayeredImage(tokenMeta.layer_image);
            } else {
               setLayeredImage('');
            }
         } else {
            setHasRealImage(false);
         }

         setTokenImage(theTokenImage);
         setVisibleImage(theTokenImage);
         setIsMainActive(true);

      } else {
         //external image?
         if (props.tokenImage !== null) {
            setTokenImage(props.tokenImage);
            setVisibleImage(props.tokenImage);
            setTraitsOfToken([]);
         }
         setHasRealImage(false);
      }

      //card type from id, and id
      setSerialNr(tokenMeta.id);
      setCardType(props.cardType ? props.cardType : tokenMeta.id < 10 ? 4 : tokenMeta.id < 100 ? 0 : tokenMeta.id < 1000 ? 1 : 3);

      //Set traits from  meta
      if (tokenMeta.traits && tokenMeta.traits.length > 0) {
         //let res= getTraitsFromHexString(tokenMeta.trait_bits.toString(16));
         // Phoenix Trait
         setPhoenixTrait(tokenMeta.traits.find(trait => trait.id == 154));
         setTraitsOfToken(tokenMeta.traits.filter(trait => trait.id != 154));
         //console.log('traitz',tokenMeta.traits)
      } else {
         if (props.demoMode && props.traits) {
            setPhoenixTrait(props.traits.find(trait => trait.id == 154));
            setTraitsOfToken(props.traits.filter(trait => trait.id != 154));
         } else {
            setTraitsOfToken([]);
         }
      }

      //Is there mp4?
      if (tokenMeta.original_art_url && tokenMeta.original_art_url.indexOf('.mp4') > -1) {
         setTokenVideo(tokenMeta.original_art_url);
         setIsVideoOn(true);
      } else {
         setTokenVideo('');
         setIsVideoOn(false);
      }

      //setOriginal art if presennt
      if (tokenMeta.original_art_url) {
         setOriginalImage(tokenMeta.original_art_url);
      } else {
         setOriginalImage('');
      }
   }

   useEffect(() => {
      const setupDemo = async () => {
         if ((props.tokenJsonUri && props.tokenJsonUri !== jsonUri) && props.tokenImage === null) {
            // console.log('json uri',props.tokenJsonUri);
            setJsonUri(props.tokenJsonUri);
            let tokenMeta = await getTokenURI(props.tokenJsonUri);

            setupMeta(tokenMeta);
         }

         if (props.tokenId && props.tokenId !== tokenId) {
            setTokenId(props.tokenId);
         }
      }


      //demo mode
      if (props.demoMode) {
         setupDemo();
         return;
      }

      //wallet mode
      if (props.provider && provider.network.name === '') {
         setProvider(props.provider);
      }

      if (props.contract && props.contract.address !== contract.address) {
         setContract(props.contract);
         //console.log('contract',props.contract);
      }

      if (props.tokenId && props.tokenId !== tokenId) {
         setIsFlipped(false);
         setIsMainActive(true);
         setSelectedTraitIdx(null);
         setSelectedTraitIdxDetails(0);
         setTokenId(props.tokenId);
      }

   }, [props]);


   const traitSelected = (traitIndex) => {
      setSelectedTraitIdx(traitIndex);
      setSelectedTraitIdxDetails(traitIndex);
   }



   const getFlagFromProperty = (propsArray, key) => {
      if (!propsArray || propsArray.length === 0) {
         return false;
      }

      for (let i = 0; i < propsArray.length; i++) {
         if (propsArray[i] === key) {
            return true;
         }
      }
      return false;
   }

   const renderTraitIcon = (trait) => {
      if (!trait) {
         trait = traitsOfToken[selectedTraitIdxDetails];
      }
      const traitIcon = `${process.env.PUBLIC_URL}/traits/${trait.icon}`;
      return (
         <img
            src={traitIcon}
            onError={(e) => {
               e.target.src = PLACEHOLDER;
               e.target.onerror = null;
            }}
            alt="trait icon">
         </img>
      )
   }


   const showTheLayers = () => {
      props.exploreTheLayers();
   }

   const creatorHasImage = (serialNr) => [0, 1, 2, 3, 4, 5].includes(serialNr);

   const callExternalImageViewer = () => {
      if (props.showImage && typeof props.showImage === 'function') {

         if (isMainActive) {
            if (tokenVideo !== '') {
               props.showImage({ type: 'video', media: tokenVideo });
            } else {
               props.showImage({ type: (serialNr < 1000 && (serialNr > 9 || creatorHasImage(serialNr))) ? 'image' : 'layered', media: originalImage ? originalImage : tokenImage });
            }
         } else {
            props.showImage({ type: 'layered', media: layeredImage });
         }
      }
   }

   const renderTraitButton = (ids, url, btnText, withWallet = true) => {
      const { id } = traitsOfToken[selectedTraitIdxDetails];
      const match = Array.isArray(ids) ? ids.includes(id) : id === ids;
      if (match) {
         return (
            <a href={`${url}${withWallet ? `?wallet=${wallet?.name}` : ''}`} type="button" className="btn btn-sm btn-peach btn-outline round">
               {btnText}
            </a>
         );
      }
      return <></>;
   };

   return (
      <>
         <div ref={containerRef} className={`nft-token embed ${containerSize} ${cardTypes[cardType].cName}`}>
            <div id="cbase" className={`flipper ${isFlipped ? 'flipped' : ''}`} onTransitionEnd={(e) => { if (e.propertyName === 'transform' && e.target.id === 'cbase') { console.log('FLIPPED!', isFlipped); setIsMainActive(!isFlipped); } }}>
               <div className={`face front 
               ${!initialised ? 'init-progress' : ''}  
               ${phoenixVisible && 'phoenix-on'} 
               ${giftVisible && 'gift-on'} 
               ${dustVisible && 'dust-on'} 
               ${traitsVisible ? 'traits-on' : ''} 
               ${detailsVisible ? 'details-on' : ''} 
               ${cardTypes[cardType].cName}`}>

                  <div className="trait-slide pt-4 pb-3 px-3 text-center">
                     <div className="close-btn" onClick={(e) => { if (selectedTraitIdx !== null) { setSelectedTraitIdx(null) } else { setTraitsVisible(!traitsVisible) } }}>
                        <img src={closeX} alt="close" />
                     </div>

                     <div className={`trait-container ${selectedTraitIdx != null ? 'trait-selected' : ''}`}>
                        {traitsOfToken.map((trait, i) => {
                           return (
                              <div key={'trait-' + i}
                                 className="trait d-flex justify-content-between align-items-center"
                                 onClick={(e) => traitSelected(i)}>

                                 <div style={{ maxWidth: "40px", width: "25%" }}>{renderTraitIcon(trait)}</div>
                                 <div style={{ width: "75%" }} >{trait.name}</div>
                              </div>
                           )
                        })}
                     </div>
                     {traitsOfToken.length > 0 &&
                        <div className={`trait-details ${selectedTraitIdx != null ? 'trait-selected' : ''}`}>
                           <div className={`trait`}
                              style={{
                                 width: '30%',
                                 height: '30%',
                              }}> {renderTraitIcon()}
                           </div>
                           <h5 className="trait-name">{traitsOfToken[selectedTraitIdxDetails].name}</h5>
                           <p className="mt-3 px-3">
                              {traitsOfToken[selectedTraitIdxDetails].description}
                           </p>
                           {props.userIsOwner && (
                              <>
                                 {renderTraitButton([106, 107], `${config.CARD_UPGRADE_URL}/#/${props.tokenId}`, 'UPGRADE NOW')}
                                 {renderTraitButton(120, `${config.CARD_SWAP_URL}/#/${props.tokenId}`, 'SWAP NOW')}
                                 {renderTraitButton(132, `${config.GOOD_COMPANY_URL}/#/${props.tokenId}`, 'GET COMPANY')}
                              </>
                           )}
                        </div>}
                  </div>

                  <div className="details-slide text-center px-2">
                     <div className="close-btn" onClick={(e) => { setDetailsVisible(false) }}>
                        <img src={closeX} alt="close" />
                     </div>
                     <div className="details-content">
                        <h3 className="mt-0 mb-0">{cardTypes[cardType].type}</h3>
                        <h5 className="mt-0 mb-4">{serialNr}</h5>
                        <div className="row">
                           <div className="col-4">
                              <p><strong>Title:</strong></p>
                           </div>
                           <div className="col-8 text-left">
                              <p className="nonclickable">{artTitle}</p>
                           </div>
                        </div>
                        {artist !== '' && <div className="row">
                           <div className="col-4">
                              <p><strong>Artist:</strong></p>
                           </div>
                           <div className="col-8 text-left">
                              <p>{artist}</p>
                           </div>
                        </div>}
                        {(layerArtists && layerArtists.length > 0) && <div className="row mt-1">
                           <div className="col-4">
                              <p><strong>Layer artists:</strong></p>
                           </div>
                           <div className="col-8 text-left">
                              {layerArtists.map((item, idx) => {
                                 return (
                                    <p key={item + idx} className="mb-0">{unescape(item)}</p>
                                 )
                              })}

                           </div>
                        </div>}
                     </div>
                  </div>

                  {/* Card Buttons with equal spacing */}
                  <div className="row card-buttons mt-2 pt-1">

                     <div className="col-12 d-flex justify-content-between mx-0">
                        <div className="card-type position-relative" onClick={(e) => { setDetailsVisible(true); }}>
                           {cardTypes[cardType].type}
                        </div>

                        <StyledDustAmountButton
                           amount={dustAmount}
                           showButton={!(popupVisible)}
                           onClick={() => setDustVisible(true)}
                        />

                        {traitsOfToken.length > 0 &&
                           <div className="card-traits position-relative" style={{ marginLeft: '10px' }} onClick={(e) => setTraitsVisible(!traitsVisible)}>
                              {traitsOfToken.length}
                           </div>
                        }
                     </div>

                     <div className="col-12 d-flex flex-row-reverse justify-content-between mx-0 mt-2">
                        <StyledEmbedButtonIcon
                           iconSrc={phoenixIcon}
                           showButton={!(popupVisible) && phoenixTrait}
                           hasTrait={phoenixTrait}
                           onClick={() => setPhoenixVisible(true)}
                        />
                     </div>

                     <div className="col-12 d-flex flex-row-reverse justify-content-between mx-0 mt-2">
                        <StyledGiftButton
                           showButton={!(popupVisible) && giftButtonVisible}
                           onClick={() => setGiftVisible(true)}
                        />
                     </div>
                  </div>

                  <EmbedPopup
                     setVisible={setPhoenixVisible}
                     visible={phoenixVisible}
                     icon={phoenixIcon}
                     title={phoenixTrait && phoenixTrait.name}
                     description={phoenixTrait && phoenixTrait.description + " See <a href='https://traits.ether.cards/' target='_blank'>traits.ether.cards</a> for more info."}
                  />

                  <DustCardEmbedPopup
                     setDustValue={(value) => {
                        props.setDustValue && props.setDustValue(value);
                        setDustAmount(value);
                     }}
                     userIsOwner={props.userIsOwner}
                     tokenId={tokenId}
                     setDustVisible={setDustVisible}
                     dustVisible={dustVisible}
                     showTncAndClaim={props.showTncAndClaim}
                  />

                  <GiftEmbedPopup
                     userIsOwner={props.userIsOwner}
                     tokenId={tokenId}
                     setVisible={setGiftVisible}
                     visible={giftVisible}
                     setButtonVisible={setGiftButtonVisible}
                  />

                  <div className="fullscreen-button">
                     {props.showImage &&
                        <div className="fs" style={{ backgroundImage: `url(${fsIcon})` }} onClick={callExternalImageViewer}></div>}

                     {(layeredImage && layeredImage !== tokenImage) &&
                        <div className="fs sw ml-3" style={{ backgroundImage: `url(${switchIcon})` }} onClick={() => { setIsFlipped(!isFlipped); }}></div>}

                     {((layeredImage && layeredImage !== tokenImage && !isMainActive) || layeredImage === tokenImage) && props.exploreTheLayers &&
                        <div className="fs sw ml-3" style={{ backgroundImage: `url(${layerIcon})` }} onClick={showTheLayers}></div>}
                  </div>

                  <div className="ec-logo">
                     <img src={ecLogo} alt="ethercards" />
                  </div>

                  {layeredImage !== tokenImage ?
                     <div className="token-image">
                        <RemoteImage src={tokenImage} />
                        <div className={`video-container ${isVideoOn ? 'playing' : ''} ${videoToFill ? "fill" : ''}`} dangerouslySetInnerHTML={
                           {
                              __html: `
                                        <video
                                        loop
                                        muted
                                        autoplay
                                        playsinline
                                        src="${tokenVideo}"
                                        />`}}>
                        </div>
                     </div>
                     :
                     <LayeredImageViewer key="fndr" show={layeredImage === tokenImage} id='FOUNDER' src={layeredImage} embedded={true} />
                  }


               </div>
               <div className="face back">


                  <div className="fullscreen-button">
                     {props.showImage && <div className="fs" style={{ backgroundImage: `url(${fsIcon})` }} onClick={callExternalImageViewer}></div>}

                     {(layeredImage && layeredImage !== tokenImage) &&
                        <div className="fs sw ml-3" style={{ backgroundImage: `url(${switchIcon})` }} onClick={() => { setIsFlipped(!isFlipped); setIsMainActive(true) }}></div>}

                     {((layeredImage && layeredImage !== tokenImage && !isMainActive) || layeredImage === tokenImage) && props.exploreTheLayers &&
                        <div className="fs sw ml-3" style={{ backgroundImage: `url(${layerIcon})` }} onClick={showTheLayers}></div>}



                  </div>
                  <div className="ec-logo">
                     <img src={ecLogo} alt="ethercards" />
                  </div>

                  <LayeredImageViewer key="og" show={isFlipped} id='OG+ALHA' src={layeredImage} embedded={true} />
               </div>
            </div>
         </div>
      </>
   );

}

export default EmbeddedCard;