import { useRef } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import terms from "../../../constants/dustTnc";
import { Container } from "styled-bootstrap-grid";
import { RowJustify } from "../../../components/Row";
import { PinkOutline } from "../../../components/Button";
import { Link } from "react-router-dom";
import closeIcon from "../../../assets/images/nft-icons/close_button.svg";
import { media } from "styled-bootstrap-grid";

const Wrapper = styled.div`
  padding: 100px 0;
  position: fixed;
  display: ${({ show }) => (show ? "visible" : "none")};
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  overflow: scroll;
  color: white;
  font-weight: 400;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 30px;
`;

const StyledPinkOutline = styled(PinkOutline)`
  margin: 10px;
`;

const CloseButtonWrapper = styled.div`
  position: fixed;
  cursor: pointer;
  top: 5%;
  right: 5%;
  width: 30px;
  height: 30px;

  &:hover {
    color: #000;
    text-decoration: none;
    opacity: .75;
  }
  ${media.xs`
    width: 16px;
    height: 16px;
  `}
  ${media.sm`
    width: 24px;
    height: 24px;
  `}
  ${media.md`
    width: 30px;
    height: 30px;
  `}
`;

const CloseIcon = styled.img`
  color: #fff;
  text-shadow: 0 1px 0 #333;
  opacity: 1;
`;

const CloseButton = ({ hideTnc }) => {
  return (
    <CloseButtonWrapper
      onClick={() => {
        hideTnc();
      }}
    >
      <CloseIcon src={closeIcon} alt="" />
    </CloseButtonWrapper>
  );
};

const DustTnc = ({ showTnc, hideTnc }) => {
  const ref = useRef();
  ref && ref.current && ref.current.scrollTo(0, 0);

  const claimData = typeof showTnc == "object" && showTnc;

  return (
    <Wrapper show={showTnc != null} ref={ref}>
      <Container style={{ position: 'relative' }}>
        <CloseButton hideTnc={hideTnc} />
        <Title>Terms & Conditions</Title>
        <ReactMarkdown children={terms} />

        <RowJustify justify="center">
          <StyledPinkOutline onClick={() => hideTnc()}>Back</StyledPinkOutline>
          {claimData && (
            <StyledPinkOutline as={Link} to={claimData}>
              Accept All
            </StyledPinkOutline>
          )}
        </RowJustify>
      </Container>
    </Wrapper>
  );
};

export default DustTnc;
