import React, { useContext, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import { ECNav } from "ec-commons";
import Web3Ctx from "../../components/Context/Web3Ctx";
import {
  useDirtyRobotTokenContract,
  useZoomContract,
  useGiveawayRegistryContract,
} from "../../hooks/useContract";
import config from "../../config";
import { toast } from "react-toast";
import { useHistory } from "react-router";
import queryString from "query-string";
import { getGiveawayInfos } from "./util";

import { SpinnerDotted } from "spinners-react";
import { DustAmountClaimButton } from "./components";
import { Container as BootStrapContainer } from "styled-bootstrap-grid";
import { Row, RowJustify } from "../../components/Row";
import { ColAlign, Col } from "../../components/Col";
import { PinkOutline, ButtonPink } from "../../components/Button";
import { LinkPink } from "../../components/Link";

import DustCheckImg from "../../assets/images/checkmark2.png";
import { media } from "styled-bootstrap-grid";

import {
  FloatingBoxCards,
  SpinningDustHexagon,
} from "../../components/Animation";

import { BitArray } from "@ethercards/ec-util";

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled(BootStrapContainer)`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 100px;
`;

const Title = styled.h2`
  color: white;
  font-family: "Poppins";
  text-align: center;
  margin-bottom: 20px;
  margin-top: 0;
`;

const Text = styled.p`
  font-family: "Poppins";
  color: #9a989a;
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 400;
`;

const ImageOverlay = styled.img`
  position: absolute;
  width: 300px;
  top: 135px;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  animation: ${fadeInAnimation} 2s linear 0.5s 1 normal forwards;
`;

const FullScreenLoader = styled.div`
  display: ${({ show }) => (show ? "visible" : "none")};
  z-index: 2000;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  padding-top: 10%;
`;

const GiftWithdrawalPage = (props) => {
  const { onboard, address, ethersProvider, chainId } = useContext(Web3Ctx);

  const [showLoader, setShowLoader] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [txCompleted, setTxCompleted] = useState(false);
  const [txEtherScan, setTxEtherScan] = useState(null);
  // const [giftAmount, setGiftAmount] = useState(0);
  const [tokenId, setTokenId] = useState(null);
  const [tokenIds, setTokenIds] = useState(null);
  // const [claimableIds, setClaimableIds] = useState(null);
  const [giveawayId, setGiveawayId] = useState(null);
  const [claimableInfos, setClaimableInfos] = useState([]);
  const [osUrl, setOsUrl] = useState(null);

  const history = useHistory();

  const giftContract = useGiveawayRegistryContract();
  const drTokenContract = useDirtyRobotTokenContract();
  const zoomContract = useZoomContract();

  useEffect(() => {
    if (giftContract) {
      const claimIds = tokenId ? [tokenId] : tokenIds ? tokenIds : [];
      if (claimIds && claimIds.length > 0) {
        fetchGiftWinners(claimIds);
      }
    }
  }, [giftContract, tokenIds, tokenId]);

  useEffect(() => {
    if (!address) {
      history.push("/wallet");
    }
  }, [address]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!address) {
      history.push("/wallet");
    }

    const { tokenId, tokenIds } = props.location.search
      ? queryString.parse(props.location.search)
      : props.location;
    if (tokenId) {
      setTokenId(Number(tokenId));
    } else if (tokenIds) {
      setTokenIds(tokenIds);
    } else {
      history.push("/wallet");
    }
  }, []);

  const fetchGiftWinners = async (tokenIds) => {
    setShowSpinner(true);

    const filteredIds = tokenIds.filter((tokenId) => Number(tokenId) >= 10);

    const giveawayCount = await giftContract.giveawayCount();

    const giveawayInfos = await getGiveawayInfos(
      giveawayCount,
      giftContract,
      zoomContract
    );

    const claimableGiveawayInfos = [];

    giveawayInfos.forEach((giveawayInfo) => {
      const { winners, claimers, name, id } = giveawayInfo;

      const filteredWinners = winners.filter((winner) =>
        filteredIds.includes(winner)
      );
      const canClaim = filteredWinners.filter(
        (winner) => !claimers.includes(winner)
      );

      console.log(name, canClaim);

      if (canClaim.length > 0) {
        claimableGiveawayInfos.push({
          giveawayId: id,
          claimableIds: canClaim,
          name: name,
        });
      }
    });

    setClaimableInfos(claimableGiveawayInfos);
    setShowSpinner(false);
  };

  const claimGift = async (claimContract, claimableInfo) => {
    if (
      showLoader ||
      txInProgress ||
      !claimContract ||
      !claimableInfo.claimableIds
    )
      return;

    setShowLoader(true);

    if (claimableInfo.claimableIds.length == 0) {
      toast.error(`Don't have eligible Id for claiming`);
      setShowLoader(false);
      return;
    }

    await withdrawNow(
      claimContract,
      [claimableInfo.giveawayId],
      [claimableInfo.claimableIds]
    );

    setShowLoader(false);
  };

  const claimAllGifts = async (claimContract, claimableInfos) => {
    const claimableIds = [];
    const giveawayIds = [];

    claimableInfos.forEach((info) => {
      if (info.claimableIds.length > 0) {
        claimableIds.push(info.claimableIds);
        giveawayIds.push(info.giveawayId);
      }
    });

    if (showLoader || txInProgress || !claimContract) return;

    setShowLoader(true);

    if (giveawayIds.length == 0) {
      toast.error(`Don't have eligible Id for claiming`);
      setShowLoader(false);
      return;
    }

    console.log(claimableIds);
    console.log(giveawayIds);

    await withdrawNow(claimContract, giveawayIds, claimableIds);

    setShowLoader(false);
  };

  const withdrawNow = async (claimContract, giveawayIds, claimableIds) => {
    return claimContract
      .claim(giveawayIds, claimableIds)
      .then((tx) => {
        setTxEtherScan(`${config.ETHERSCAN_URL}tx/` + tx.hash);
        setShowLoader(false);
        setTxInProgress(true);
        window.scrollTo(0, 0);

        // setOsUrl(`${config.OPENSEA_ASSET_URL}${drTokenContract.address}/`)
        // drTokenContract.on("Transfer", (from, to, tokenId) => {
        //   if (to.toLowerCase() === address.toLowerCase()) {
        //     console.log("TokenId: ", tokenId);
        //     drTokenContract.removeAllListeners("Transfer");
        //     setOsUrl(`${config.OPENSEA_ASSET_URL}${drTokenContract.address}/${tokenId}`)
        //   }
        // });

        return tx.wait().then(async (receipt) => {
          console.log("txReceipt: ", receipt);
          if (receipt && receipt.status === 1) {
            toast.success("Successfully claimed Gift");
            // setTxEtherScan(null);
            setTxCompleted(true);
          } else {
            toast.error("Transaction Failed");
            setTxEtherScan(null);
            setTxInProgress(false);
          }
        });
      })
      .catch(handleError);
  };

  const handleError = (e) => {
    if (e.error && e.error.message) {
      toast.error(e.error.message);
    } else if (e.message) {
      toast.error(e.message);
    } else if (e.reason) {
      toast.error(e.reason);
    }
  };

  return (
    <>
      <ECNav
        projectUrl={config.APP_BASE_URL}
        onboard={onboard}
        address={address}
      />

      <FullScreenLoader show={showLoader} />

      <Container className="container">
        {!txInProgress && !txCompleted && (
          <>
            <FloatingBoxCards />
            <Title>
              {tokenId
                ? `Claim Gift of Card #${tokenId}`
                : `Claim all your Gifts`}
            </Title>

            {showSpinner && (
              <Row>
                <ColAlign align="center">
                  <SpinnerDotted
                    size={30}
                    thickness={160}
                    speed={100}
                    color="rgba(255, 255, 255, 0.5)"
                  ></SpinnerDotted>
                </ColAlign>
              </Row>
            )}

            <>
              <ColAlign col={12} md={12} lg={9} align="center">
                {claimableInfos.map((claimableInfo) => {
                  const length = claimableInfo.claimableIds.length;
                  return (
                    <DustAmountClaimButton
                      key={claimableInfo.name}
                      amount={length}
                      text={claimableInfo.name}
                      onClick={() => {
                        claimGift(giftContract, claimableInfo);
                      }}
                    />
                  );
                })}
              </ColAlign>

              <Row>
                <ColAlign
                  col
                  md={8}
                  align="center"
                  style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}
                >
                  <Text>
                    Each Ether Cards Free drop has its own unique smart
                    contract. As such, users can claim from one drop (smart
                    contract) at a time. Each drop claim will be processed as a
                    separate transaction.
                  </Text>
                </ColAlign>
              </Row>

              <RowJustify justify="center">
                <PinkOutline
                  as={Link}
                  to={{
                    pathname: `/wallet`,
                    tokenId: tokenId,
                  }}
                  style={{ marginRight: "0.5rem" }}
                >
                  Cancel
                </PinkOutline>

                {claimableInfos.length > 1 && (
                  <PinkOutline
                    style={{ marginLeft: "0.5rem" }}
                    onClick={() => {
                      claimAllGifts(giftContract, claimableInfos);
                    }}
                  >
                    Claim All
                  </PinkOutline>
                )}
              </RowJustify>
            </>
          </>
        )}

        {txInProgress && !txCompleted && (
          <>
            <FloatingBoxCards />
            <Title>Transaction is processing</Title>
            <Row>
              <ColAlign
                col
                md={8}
                align="center"
                style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}
              >
                <Text>
                  Please wait until the transaction is complete. You can check
                  the status on{" "}
                  <LinkPink href={txEtherScan} target="_blank">
                    Etherscan.
                  </LinkPink>
                </Text>
              </ColAlign>
            </Row>
          </>
        )}

        {txCompleted && (
          <>
            <FloatingBoxCards />
            <ImageOverlay src={DustCheckImg}></ImageOverlay>
            <Title style={{ marginTop: "50px" }}>
              Transaction was successful
            </Title>
            <Row>
              <ColAlign
                col={12}
                md={10}
                lg={8}
                align="center"
                style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}
              >
                <Text>
                  Congratulations! The gift has been transferred to your wallet
                </Text>
              </ColAlign>
            </Row>
            <Row>
              <ColAlign col={12} md={6} align={"center"} mdAlign={"end"} order={2} mdOrder={1}>
                <PinkOutline
                  style={{ marginBottom: "1.5rem" }}
                  as={Link}
                  to="/wallet"
                >
                  Back to wallet
                </PinkOutline>
              </ColAlign>
              <ColAlign col={12} md={6} align={"center"} mdAlign={"start"} order={1} mdOrder={2}>
                <PinkOutline
                  style={{ marginBottom: "1.5rem"}}
                  as={"a"}
                  href={txEtherScan}
                  target={"_blank"}
                >
                  View your transaction
                </PinkOutline>
              </ColAlign>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default GiftWithdrawalPage;
