import styled from "styled-components"

export const Link = styled.a`
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;

    :hover {
    text-decoration: underline;
    }

    :focus {
    outline: none;
    text-decoration: underline;
    }

    :active {
    text-decoration: none;
    }
`

export const LinkPink = styled(Link)`
    color: #F51CDD;

    :focus {
        outline: none;
        text-decoration: none;
        color: #F51CDD;
    }

    :hover {
        text-decoration: none;
        color: #F51CDD;
        opacity: 0.8;
    }
`