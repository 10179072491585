import AuthPage from "./components/Auth/AuthPage";
import RubberSpider from "./components/gumipok/RubberSpider";
import Home from "./components/Home/Home";
import WalletPage from "./components/Wallet/WalletPage";
import DustPage from "./pages/Dust/DustWithdrawalPage";
import GiftPage from "./pages/Gift/GiftWithdrawalPage";

const routes = [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/wallet/gift",
    component: GiftPage,
    exact: true,
  },
  {
    path: "/wallet/dust",
    component: DustPage,
    exact: true,
  },
  {
    path: "/:series/:tokenId",
    component: Home,
    exact: true,
  },
  {
    path: "/embed/:series/:tokenId",
    component: RubberSpider,
    exact: true,
  },
  {
    path: "/wallet",
    component: WalletPage,
    exact: true,
  },
  {
    path: "/wallet/show/:tokenIndex",
    component: WalletPage,
    exact: true,
  },
  {
    path: "/auth",
    component: AuthPage,
    exact: true,
  },
];

export default routes;
