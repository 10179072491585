import { useRef, useEffect } from 'react';

const useUnload = fn => {
  const cb = useRef(fn); 

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener("beforeunload", onUnload);
    window.addEventListener("unload", onUnload);
    //('add listeners',onUnload);

    return () => {
      //console.log('remove listeners');
      window.removeEventListener("beforeunload", onUnload);
      window.removeEventListener("unload", onUnload);
    }
  }, []);
};

export default useUnload;