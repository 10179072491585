import Card from "../../assets/images/float-card.png";
import DustCoin from "../../assets/images/d-coin.png";
import HeadIcon from "../../assets/images/head-icon.png";
import PlanetIcon from "../../assets/images/planet-icon.png";
import styled, { keyframes } from "styled-components";
import { media } from "styled-bootstrap-grid";

const floatAnimation = keyframes`
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px);
    }
    100% {
        transform: translatey(0px);
    }
`;

const glowAnimation = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
`;

const Wrapper = styled.div`
  position: relative;
  margin: auto;
  ${media.xs`
    height: 300px;
    width: 300px;
  `}
  ${media.sm`
    height: 350px;
    width: 350px;
  `}
  ${media.md`
    height: 350px;
    width: 350px;
  `}
`;
const BaseImage = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  animation-name: ${glowAnimation};
  animation-duration: 6s;
  animation-iteration-count: infinite;
`;

const CoinImage = styled.img`
  height: 30%;
  position: absolute;
  left: 0;
  right: -20%;
  bottom: 15%;
  margin: auto;
  animation-name: ${floatAnimation};
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
`;
const HeadIconImage = styled.img`
  height: 7%;
  position: absolute;
  left: -55%;
  right: 0;
  top: -50%;
  bottom: 0;
  margin: auto;
  animation-name: ${floatAnimation};
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
`;
const SaturnIconImage = styled.img`
  height: 10%;
  position: absolute;
  left: -40%;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  animation-name: ${floatAnimation};
`;

const FloatingCardCoins = () => {
  return (
    <Wrapper>
      <BaseImage src={Card}></BaseImage>
      <CoinImage src={DustCoin}></CoinImage>
      <HeadIconImage src={HeadIcon}></HeadIconImage>
      <SaturnIconImage src={PlanetIcon}></SaturnIconImage>
    </Wrapper>
  );
};

export default FloatingCardCoins;
