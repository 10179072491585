import parse from 'html-react-parser';
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import closeX from "../../assets/images/nft-icons/close_button.svg";

const CloseButton = styled.div`
  position: fixed;
  top: 15px;
  right: 15px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: white;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  & img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    filter: brightness(0) saturate(100) invert(23%) sepia(93%) saturate(5853%)
      hue-rotate(286deg) brightness(98%) contrast(93%);
  }
`;

const SlidePopup = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  transform-origin: ${({ transformOrigin }) =>
    transformOrigin
      ? `${transformOrigin.x}% ${transformOrigin.y}%`
      : "100% 16%"};
  z-index: 11;
  overflow: hidden;
  transform: ${({ showPopup }) => (showPopup ? "scale(1)" : "scale(0)")};
  opacity: ${({ showPopup }) => (showPopup ? 1 : 0)};
  transition: all 0.4s ease-in-out;
  text-align: center;
`;

const SlideContent = styled.div`
  position: absolute;
  width: 92%;
  left: 4%;
  right: 4%;
  top: 10%;
  bottom: 4%;
  font-size: 1rem;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
  overflow: auto;

  & a {
    color: var(--primary-color);
  }
  & a:hover {
    color: var(--primary-color);
  }
`;

const Icon = styled.img`
  height: 50px;
  margin-bottom: 10px;
`;

const Title = styled.h4`
  // font-family: Teko;
  margin-top: 10px;
  margin-bottom: 30px;
  ${media.xs`
    margin-bottom: 10px;
  `}
`;

const Description = styled.p`
  color: #faf5fa;
  opacity: 0.64;
  font-size: 0.8em;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 30px;
`;

const Text = styled.div`
  font-family: Poppins;
  font-size: 1.1rem;
  font-weight: 500;
  color: #grey;
`;

const EmbedPopup = ({
  setVisible,
  visible,
  title,
  description,
  transformOrigin,
  icon
}) => {
  return (
    <>
      <SlidePopup
        showPopup={visible}
        className="px-2"
        transformOrigin={transformOrigin}
      >
        <CloseButton
          onClick={(e) => {
            setVisible(false);
          }}
        >
          <img src={closeX} alt="close" />
        </CloseButton>

        <SlideContent>
          <Icon src={icon}></Icon>
          <Title>{title}</Title>
          <Description>{parse(description || "")}</Description>
        </SlideContent>
      </SlidePopup>
    </>
  );
};

export default EmbedPopup;
