import React from "react";
import { ButtonPink } from "../../../components/Button";
import styled from "styled-components";
import dustIcon from "../../../assets/images/dust-icon.png";
import { truncateAmount } from "../../../components/Utils";

const DustAmountBtn = styled(ButtonPink)`
  height: auto;
  font-family: Poppins;
  font-weight: 600;
  padding: 0em 0.5em 0em 0.5em;
  font-size: 1em;
  border-radius: 0.6rem;
  background-color: #000;
  min-width: unset;
  opacity: 1;
  text-align: center;
  min-width: 2.4em;
  transition: opacity 0.4s ease-in-out, background-color 0.4s ease-in-out;
  z-index: 11;

  &:hover {
    background-color: #f51cdd;
  }
`;

const DustIcon = styled.img`
  height: 0.8em;
  //margin-top: -4px;
  margin-right: 5px;
  margin-bottom: 3px;
`;

const DustAmountEmbedButton = ({ className, amount, onClick }) => {

  return (
    <DustAmountBtn className={className} onClick={onClick}>
      <DustIcon src={dustIcon} />
      {truncateAmount(amount, 2)}
    </DustAmountBtn>
  );
};

export default DustAmountEmbedButton;
