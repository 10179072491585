import React, { useEffect, useState, useContext, useMemo } from "react";
import closeX from "../../../assets/images/nft-icons/close_button.svg";
import styled from "styled-components";
import { DustAmountEmbedButton } from ".";
import dustIcon from "../../../assets/images/dust-icon.png";
import { PinkOutline } from "../../../components/Button";
import { Link } from "react-router-dom";
import Web3Ctx from "../../../components/Context/Web3Ctx";
import {
  useDustDispenserContract,
  useDustDispenserV2Contract,
  useDustExtraAllocatorContract,
  useDustTokenContract,
} from "../../../hooks/useContract";
import { useLocation } from "react-router";
import { formatDustAmount } from "../../../components/Utils";
import { media } from "styled-bootstrap-grid";

const CloseButton = styled.div`
  position: fixed;
  top: 15px;
  right: 15px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: white;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  & img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    filter: brightness(0) saturate(100) invert(23%) sepia(93%) saturate(5853%)
      hue-rotate(286deg) brightness(98%) contrast(93%);
  }
`;

const SlidePopup = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  transform-origin: top center;
  z-index: 11;
  overflow: hidden;
  transform: ${({ showPopup }) => (showPopup ? "scale(1)" : "scale(0)")};
  opacity: ${({ showPopup }) => (showPopup ? 1 : 0)};
  transition: all 0.4s ease-in-out;
  text-align: center;
`;

const SlideContent = styled.div`
  position: absolute;
  width: 92%;
  left: 4%;
  right: 4%;
  top: 10%;
  bottom: 4%;
  font-size: 1rem;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
  overflow: auto;
`;

const StyledDustAmountButton = styled(DustAmountEmbedButton)`
  position: absolute;
  top: 3%;
  left: 0;
  right: 0;
  z-index: 11;
  opacity: ${({ showButton }) => (showButton ? 1 : 0)};
  transition: all 0.4s ease-in-out;
`;

const DustIcon = styled.img`
  height: 50px;
  margin-bottom: 10px;
`;

const Title = styled.h4`
  // font-family: Teko;
  margin-top: 10px;
  margin-bottom: 30px;
  ${media.xs`
    margin-bottom: 10px;
  `}
`;

const Description = styled.p`
  color: #faf5fa;
  opacity: 0.64;
  font-size: 0.8em;
  margin-left: 5%;
  margin-right: 5%;
`;

const Text = styled.div`
  font-family: Poppins;
  font-size: 1.1rem;
  font-weight: 500;
  color: #grey;
  margin-bottom: 10%;
  margin-top: 10%;
`;

const Amount = styled.span`
  color: white;
  font-weight: 400;
  font-size: 1.1rem;
`;

const DustCardEmbedPopup = ({
  userIsOwner,
  tokenId,
  setDustVisible,
  dustVisible,
  setDustValue,
  showButton,
  showTncAndClaim,
}) => {
  const { ethersProvider, chainId } = useContext(Web3Ctx);
  const [showPopup, setShowPopup] = useState(false);
  const [dustAmount, setDustAmount] = useState("0");
  const [claimLocked, setClaimLocked] = useState(true);
  const [isEmbed, setIsEmbed] = useState(false);
  let location = useLocation();

  const dustContract = useDustDispenserContract();
  const dustExtraContract = useDustExtraAllocatorContract();
  const dustExtra2Contract = useDustDispenserV2Contract();

  useEffect(() => {
    setIsEmbed(location.pathname && location.pathname.includes("embed/"));
  }, [location]);

  useEffect(() => {
    if (tokenId) {
      getDustAmount(tokenId);
    }
  }, [tokenId]);

  useEffect(() => {
    if (setDustValue) {
      // console.log("setting dust value");
      setDustValue(dustAmount);
    }
  }, [dustAmount]);

  const getDustAmount = async (tokenId) => {
    if (Number(tokenId) < 10) return;
    if (dustContract) {
      checkContractLocked();
      const dust = await dustContract["getAvailableBalance"]([Number(tokenId)]);
      const extraDust = await dustExtraContract["getAvailableBalance"]([
        Number(tokenId),
      ]);

      console.log(dustExtra2Contract);
      const extraDust2 = await dustExtra2Contract[
        "getAvailableBalance(uint16[])"
      ]([Number(tokenId)]);

      const sumOfDust = dust.add(extraDust).add(extraDust2);
      if (sumOfDust > 0) {
        setDustAmount(formatDustAmount(sumOfDust));
      } else {
        setDustAmount("0");
      }
    }
  };

  const checkContractLocked = async () => {
    const locked = await dustContract["locked"]();
    setClaimLocked(locked);
  };

  const showClaimButton =
    userIsOwner && !claimLocked && typeof showTncAndClaim == "function";

  return (
    <>
      {/* <StyledDustAmountButton
        amount={dustAmount}
        showButton={!showPopup && showButton}
        onClick={() => setShowPopup(true)}
      /> */}
      <SlidePopup showPopup={dustVisible} className="px-2">
        <CloseButton
          onClick={(e) => {
            // setShowPopup(false);
            setDustVisible(false);
          }}
        >
          <img src={closeX} alt="close" />
        </CloseButton>

        <SlideContent>
          <DustIcon src={dustIcon}></DustIcon>
          <Title>Dust</Title>
          <Description>
            Dust is Ether Card’s utility token that will be used across the
            entire Ether Cards ecosystem.
          </Description>
          <Text>
            Amount: <Amount>{dustAmount}</Amount>
          </Text>
          {showClaimButton && (
            <PinkOutline
              disabled={Number(dustAmount) == 0}
              onClick={() => {
                showTncAndClaim({
                  pathname: `/wallet/dust`,
                  tokenId: tokenId,
                  dustAmount: dustAmount,
                });
              }}
            >
              Claim
            </PinkOutline>
          )}
        </SlideContent>
      </SlidePopup>
    </>
  );
};

export default DustCardEmbedPopup;
