
const terms = 

`**DUST AIRDROP CAMPAIGN PARTICIPATION TERMS AND CONDITIONS (THESE &quot;TERMS&quot;)**

**IN THESE TERMS,**  **WORDS AND EXPRESSIONS NOT OTHERWISE DEFINED SHALL, WHERE NOT INCONSISTENT WITH THE CONTEXT, HAVE THE MEANINGS RESPECTIVELY ASCRIBED THERETO IN THE ANNEX TO THESE**  **TERMS.**

In these Terms, &quot; **we**&quot;, &quot; **us**&quot; or &quot; **Campaign Organiser**&quot; refers to a company incorporated in the British Virgin Islands named Equinoctial Inc (BVI Company Number: 2077416), and &quot; **our**&quot; shall be construed accordingly. &quot; **You**&quot; refers to any person participating in the Dust Airdrop Campaign, and &quot; **your**&quot; shall be construed accordingly.

Each of you and the Campaign Organiser shall hereinafter be referred to as a &quot; **Party**&quot;, and collectively, you and the Campaign Organiser shall hereinafter be referred to as the &quot; **Parties**&quot;.

Your participation in the Dust Airdrop Campaign is subject to these Terms as may be amended by us from time to time at our sole and absolute discretion. These Terms shall also include any other operating rules, policies and procedures which we may issue from time to time.

We may revise these Terms at any time with or without notice to you and any changes will be uploaded on the website as accessible at [https://explorer.ether.cards/#/wallet](https://explorer.ether.cards/#/wallet) (the &quot; **Website**&quot;) and/or its related social media channels (collectively &quot; **Channels**&quot;). These changes shall take effect from the date of upload and your continued access, use of the Website, or participation in the Dust Airdrop Campaign from such date shall be deemed to constitute acceptance of the new Terms. It shall be your sole responsibility to check the Website for such changes from time to time. If you do not agree to these Terms, please exit the Website immediately and do not participate in the Dust Airdrop Campaign.

By accessing, browsing or viewing the Website or participating in the Dust Airdrop Campaign, you:

1. agree to be bound by and to abide by the latest version of the Terms;

1. represent and warrant that in the jurisdiction to which you are subject of, you are of legal age to participate in the Dust Airdrop Campaign and to create binding obligations for any liability you may incur as a result of the use of the Website and/or your participation in the Dust Airdrop Campaign; and

1. represent and warrant that you are not a Disqualified Person or acting on behalf of a Disqualified Person.

**The Tokens are not intended to constitute securities of any form, units in a business trust, units in a collective investment scheme or any other form of investment in any jurisdiction. This document and these Terms do not constitute a prospectus or offer document of any sort and are not intended to constitute an offer of securities of any form, units in a business trust, units in a collective investment scheme or any other form of investment, or a solicitation for any form of investment in any jurisdiction. No regulatory authority has examined or approved of these Terms. No such action has been or will be taken by the Campaign Organiser under the laws, regulatory requirements or rules of any jurisdiction. The provision of these Terms to you does not imply that the Applicable Laws, regulatory requirements or rules have been complied with.**

The headings in these Terms are included for convenience only and shall not be taken into account in construing these Terms. References to &quot; **Paragraphs**&quot;, &quot; **Recitals**&quot; and &quot; **Annexes**&quot; are to be construed as references to the paragraphs, recitals and annexes of these Terms.

The Parties **HEREBY AGREE** as follows:

**DUST AIRDROP CAMPAIGN**

Subject to Paragraphs 1.2 and 2, to increase awareness of the Project and to encourage users to participate in the Ether Cards Ecosystem after its launch, the Campaign Organiser intends to distribute a certain number of Tokens free-of-charge as part of a marketing campaign (&quot; **Dust Airdrop Campaign**&quot;) as follows:

1. the Campaign Organiser will allocate such number of Tokens to each Ether Card NFT of the relevant type as indicated on the Website (&quot; **Allocated Airdrop Tokens**&quot;);

1. a holder of an Ether Card NFT can effect a claim function (&quot; **Claim**&quot;) to receive such Allocated Airdrop Tokens to the relevant wallet address at which such Ether Card NFT is held (&quot; **Participant Token Address**&quot;) through the Website;

1. the Allocated Airdrop Tokens in respect of an Ether Card NFT can only be claimed once; therefore, if a Claim is made in respect of an Ether Card NFT (which is not subject of any earlier Claim) and such Ether Card NFT is subsequently transferred to another wallet address (such address, &quot; **Other Wallet Address**&quot;), a holder of such Ether Card NFT at such Other Wallet Address can no longer make another Claim for the relevant Allocated Airdrop Tokens;

1. the relevant Allocated Airdrop Tokens will be made available for Claims on the Ether Card NFTs commencing **9.00 pm (GMT +8)** on **4 October 2021** and ending at such date and time as may be determined by us in our absolute discretion (&quot; **Claim Period**&quot;).

If you are an Eligible Participant and would like to Claim the Allocated Airdrop Tokens in respect of your Ether Card NFT, you must visit the Website and follow the instructions as indicated on the Website to make the Claim.

Notwithstanding anything to the contrary (including but not limited to what is stated in these Terms or as published on the Website and/or the Channels), the Campaign Organiser reserves the right to discontinue the Dust Airdrop Campaign and elect not to distribute any Tokens to any Eligible Participant for any reason as it may deem fit.

By participating in the Dust Airdrop Campaign, you acknowledge and agree that none of the members of the Project Group (including the Campaign Organiser and its Affiliates) is liable for any direct, indirect, special, incidental, consequential or other losses of any kind. In the event you acquire or purchase an Ether Card NFT which had already been subject of a Claim, you acknowledge and agree that you will not be able to receive any Allocated Airdrop Tokens subject of such Ether Card NFT and you shall have no claim against any of the members of the Project Group.

**QUALIFIED PERSONS**

Before participating in the Dust Airdrop Campaign, you must conduct your own due diligence and ensure that your participation complies with Applicable Laws. You should consult with professional advisors regarding the Dust Airdrop Campaign before participating. You shall not participate in the Dust Airdrop Campaign if there are applicable legal restrictions in your country of residence or domicile. It is your sole responsibility to ensure that your participation in the Dust Airdrop Campaign is not prohibited, restricted, curtailed, hindered, impaired or otherwise adversely affected by any Applicable Law, regulation or rule in your country of residence or domicile.

You shall not participate and you are not eligible to participate in the in the Dust Airdrop Campaign if:

1. where you are an individual, you are a citizen of, domiciled in, resident of, or physically present / located in an Excluded Jurisdiction; or

1. where you are a body corporate, you are a body corporate: (i) which is incorporated in, or operates out of, an Excluded Jurisdiction, or (ii) which is wholly or partially owned by or under the control of (aa) one or more individuals who is/are citizen(s) of, domiciled in, residents of, or physically present / located in, an Excluded Jurisdiction; (bb) one or more entities which is incorporated in, or operates out of, an Excluded Jurisdiction; and/or (cc) a Designated Person / Entity; or

1. you are an individual or body corporate : (i) included in the consolidated list published by the United Nations Security Council of individuals or entities subject to measures imposed by the United Nations Security Council accessible at https://www.un.org/securitycouncil/content/un-sc-consolidated-list; or (ii) included in the United Nations Lists (UN Lists) or within the ambit any regulations adopted by any jurisdiction relating to or implementing United Nations Security Council Resolutions (&quot; **Designated Person / Entity**&quot;); or

1. you are an individual or body corporate which is otherwise prohibited or ineligible in any way, whether in full or in part, under any laws applicable to such individual or body corporate from participating in any part of the transactions contemplated in these Terms, or

1. your participation in the Dust Airdrop Campaign is prohibited, restricted, curtailed, hindered, impaired, unauthorized or otherwise adversely affected in any way or in any form or manner whether in full or in part under any applicable law, regulation or rule in your country of residence or domicile or in any jurisdiction applicable to you; or

1. if you are unable to make a true and affirmative representation in respect of the matters set out in Paragraph 3.1(n), (p) and (q);

(collectively, the &quot; **Disqualified Persons**&quot;).

You shall also not participate and you are not eligible to participate in the Dust Airdrop Campaign if you are controlled or ultimately controlled by another Disqualified Person.

**REPRESENTATIONS, WARRANTIES AND UNDERTAKINGS**

By accepting these Terms, you represent and warrant to the Campaign Organiser as follows:

1. you agree and acknowledge that the Tokens do not constitute securities of any form, units in a business trust, units in a collective investment scheme or any other form of regulated investment or investment product in any jurisdiction;

1. you acknowledge and understand that no Token should be construed, interpreted, classified or treated as enabling, or according any opportunity to, you to participate in or receive profits, income, or other payments or returns arising from or in connection with the Ether Cards Ecosystem, the Tokens or the proceeds of any sale of Tokens, or to receive sums paid out of such profits, income, or other payments or returns;

1. you agree and acknowledge that no regulatory authority has examined or approved of these Terms, no action has been or will be taken under the laws, regulatory requirements or rules of any jurisdiction and the publication, distribution or provision of these Terms to you does not imply that the Applicable Laws, regulatory requirements or rules have been complied with;

1. the provision of these Terms, any part thereof or any copy thereof, or acceptance of the same by you, is not prohibited or restricted by the Applicable Laws, regulations or rules in your jurisdiction, and where any restrictions in relation to possession are applicable, you have observed and complied with all such restrictions at your own expense and without liability to the Project Group;

1. you have read and understood all of these Terms including the Annex hereto;

1. you are not a Disqualified Person and are not controlled or ultimately controlled by another Disqualified Person;

1. you have full power and capacity to accept these Terms and perform all your obligations hereunder and in the case where you are a corporation or acting on behalf of a corporation:

    1. such corporation is duly incorporated and validly existing under the Applicable Laws; and

    1. such corporation duly authorised to accept these Terms and perform obligations hereunder;

1. if you are a natural person, you are of sufficient age and capacity under the Applicable Laws of the jurisdiction in which you reside and the jurisdiction of which you are a citizen to accept these Terms and perform all your obligations hereunder;

1. you agree and acknowledge that the Tokens are not to be construed, interpreted, classified or treated as:

    1. debentures, stocks or shares issued by any person or entity;

    1. rights, options or derivatives in respect of such debentures, stocks or shares;

    1. rights under a contract for differences or under any other contract the purpose or pretended purpose of which is to secure a profit or avoid a loss;

    1. units in a collective investment scheme;

    1. units in a business trust;

    1. derivatives of units in a business trust; or

    1. any other security, class of securities or form of investment (whether regulated or otherwise);

1. you have a basic degree of understanding of the operation, functionality, usage, storage, transmission mechanisms and other material characteristics of cryptocurrencies, NFTs, blockchain-based software systems, cryptocurrency wallets or other related token storage mechanisms, blockchain technology and smart contract technology;

1. these Terms constitute legal, valid and binding obligations on you, enforceable in accordance with its terms and your participation in the Dust Airdrop Campaign is not in breach or contravention of any applicable law, regulation or rule in your jurisdiction;

1. you are not a citizen or resident of any jurisdiction the laws, regulations or rules of which prohibit, restrict, curtail, hinder, impair, conflict or otherwise adversely affect your participation in Dust Airdrop Campaign;

1. no consent, approval, order or authorisation of, or registration, qualification, designation, declaration or filing with, any regulatory authority in any jurisdiction (&quot; **Approvals**&quot;) is required on your part in connection with your participation in Dust Airdrop Campaign or, where any Approvals are required, such Approvals have been obtained and remain valid and in full force and effect;

1. none of you or (and in the case where you are accepting these Terms on behalf of a corporation) any of your related corporations (if any), any of your directors or officers, nor any of your employees, agents or any other person acting on behalf of your or any of your related corporations is an individual or entity that is, or is owned or controlled by an individual or entity that:

    1. is listed by the MAS as designated individuals or entities defined in the respective regulations promulgated under the Monetary Authority of Singapore Act (Chapter 186) of Singapore, the United Nations Act (Chapter 339) of Singapore or the Terrorism (Suppression of Financing) Act (Chapter 325) of Singapore or such other law, regulation or rule as may be prescribed by the MAS from time to time;

    1. is currently the subject of any sanction administered by the OFAC or any other United States government authority, is not designated as a &quot;Specially Designated National&quot; or &quot;Blocked Person&quot; by OFAC or subject to any similar sanctions or measures imposed or administered by the United Nations Security Council, the European Union, Her Majesty&#39;s Treasury of the United Kingdom or similar sanctions administered or imposed by the government of Singapore or any other country (collectively, the &quot; **Sanctions**&quot;);

    1. is located, organised or resident in a country or territory that is the subject of such Sanctions (including, without limitation, the Democratic People&#39;s Republic of Korea, the Democratic Republic of Congo, Eritea, Iran, Libya, Somalia, South Sudan, Sudan and Yemen) (&quot; **Sanctioned Country**&quot;); or

    1. has engaged in and/or is engaged in any dealings or transactions with any government, person, entity or project targeted by, or located in any country or territory, that at the time of the dealing or transaction is or was the subject of any Sanctions;

1. (in the case where you are a corporation or are accepting these Terms on behalf of a corporation) the operations of such corporation and any of its related corporations, are and have been conducted at all times in compliance with applicable financial record keeping and reporting requirements and money laundering statutes in Singapore and of all jurisdictions in which such corporation and its related corporations conduct business or operations, the rules and regulations thereunder and any related or similar rules, regulations or guidelines, issued, administered or enforced by any governmental agency or proceeding by or before any court or governmental agency (collectively, &quot; **Money Laundering Laws**&quot;) and no action, suit or proceeding by or before any court or governmental agency, authority or body or any arbitrator involving you or your related corporations with respect to Money Laundering Laws is pending and, no such actions, suits or proceedings are threatened or contemplated against such corporation or its related corporations;

1. none of: (i) you; (ii) any person controlling or controlled by you; (iii) if you are a privately-held entity, any person having a beneficial interest in you; or (iv) any person for whom you are acting as agent or nominee in connection with your participation in the Dust Airdrop Campaign, is a senior foreign political figure, or any immediate family member or close associate of a senior foreign political figure, as such terms are defined below;

1. if you are affiliated with a Foreign Bank, or if you receive deposits from, make payments on behalf of, or handle other financial transactions related to a Foreign Bank, you represent and warrant to the Campaign Organiser that: (i) the Foreign Bank has a fixed address, and not solely an electronic address, in a country in which the Foreign Bank is authorised to conduct banking activities; (ii) the Foreign Bank maintains operating records related to its banking activities; (iii) the Foreign Bank is subject to inspection by the banking authority that licensed the Foreign Bank to conduct its banking activities; and (iv) the Foreign Bank does not provide banking services to any other Foreign Bank that does not have a physical presence in any country and that is not a regulated affiliate;

1. you are participating in the Dust Airdrop Campaign as principal and for your own benefit and you are not acting on the instructions of, or as nominee or agent for or on behalf of any other person;

1. you are not participating in Dust Airdrop Campaign with a view to obtaining or using any Tokens for any illegal purpose;

1. you bear the sole responsibility to determine what tax implications (if any) your participation in the Dust Airdrop Campaign may have for you, and agree not to hold the Campaign Organiser or any other person liable for any tax liability associated with or arising therefrom;

1. you agree and acknowledge that none of the members of the Project Group (including the Campaign Organiser) is liable for any direct, indirect, special, incidental, consequential or other losses of any kind, in tort, contract or otherwise (including but not limited to loss of revenue, income or profits, and loss of use or data), arising out of or in connection with any acceptance of or reliance on these Terms or any part thereof by you;

1. all the documents and information furnished by you to the Campaign Organiser (if any) pursuant to these Terms are true, accurate and complete in all respects, and there is no matter, event, circumstance or any other information which has arisen which would make any documents and information provided misleading or incomplete, or any fact or information the omission of which would make any documents and information provided misleading or incomplete;

1. you waive the right to participate in a class action lawsuit or a class wide arbitration against any member of the Project Group (including the Campaign Organiser), the Indemnified Persons and/or any person involved in the Dust Airdrop Campaign; and

1. all of the above representations and warranties are true, complete, accurate and not misleading from the time of your access to and/or acceptance of these Terms and notwithstanding the conclusion of the Dust Airdrop Campaign.

By accepting these Terms, you hereby waive all rights, claims and/or causes of action (present or future) under law (including any tortious claims) or contract against all members of the Project Group (including the Campaign Organiser) and shall further hold harmless, all members of the Project Group (including the Campaign Organiser) from and against any and all losses, claims, liabilities, damages, suits, actions, demands, proceedings, costs, charges and/or expenses of whatsoever nature or howsoever arising, including any indirect, special, incidental, consequential or other losses of any kind, in tort, contract or otherwise (including but not limited to loss of revenue and income or profits) that may be incurred by you in connection with or arising from the Tokens that you may receive under the Dust Airdrop Campaign, the Project, services provided by any member of the Project Group, or use of the Website or Channels.

The Project Group (including the Campaign Organiser) does not make, and hereby disclaims, any representation or warranty in any form whatsoever, including any representation or warranty in relation to:

1. the information set out on the Website or any other place;

1. the Campaign Organiser; and

1. the Dust Airdrop Campaign.

You undertake to notify the Campaign Organiser of any change in the documents and information provided by you to the Campaign Organiser pursuant to these Terms (if any) and in the absence of any notification in writing notifying of any change, the Campaign Organiser is entitled to assume that the documents and information provided by you remain true, correct, not misleading and unchanged.

**DISCLAIMERS**

To the maximum extent permitted by all Applicable Laws, regulations and rules and except as otherwise provided in these Terms, the Project Group (including the Campaign Organiser) hereby expressly disclaims its liability and shall in no case be liable to you or any person for:

1. postponement, suspension and/or abortion of the Dust Airdrop Campaign and/or the Project;

1. any failure, malfunction or breakdown of, or disruption to, the Dust Airdrop Campaign, the operations of the Campaign Organiser, the Ether Cards Ecosystem or any other technology (including but not limited to smart contract technology) associated therewith, due to any reason, including but not limited to occurrences of hacks, mining attacks (including but not limited to double-spend attacks, majority mining power attacks and &quot;selfish-mining&quot; attacks), cyber-attacks, distributed denials of service, errors, vulnerabilities, defects, flaws in programming or source code or otherwise, regardless of when such failure, malfunction, breakdown, or disruption occurs;

1. any failure, malfunction, virus, error, bug, flaw, defect in the technology connected with or use for affecting the Dust Airdrop Campaign or your participation in Dust Airdrop Campaign;

1. failure to disclose information relating to the Dust Airdrop Campaign at the request of the Campaign Organiser;

1. any prohibition, restriction or regulation by any government or regulatory authority in any jurisdiction applicable to the Dust Airdrop Campaign or your participation in Dust Airdrop Campaign;

1. loss of possession of the credentials for accessing, or loss or destruction of the private keys of, any wallet, the Participant Token Address, in any manner and to any extent;

1. failure to disclose information relating to the progress of Dust Airdrop Campaign;

1. any risks associated with your participation in the Dust Airdrop Campaign, the Project Group (including the Campaign Organiser) and/or the Ether Cards Ecosystem; and

1. all other risks, direct, indirect or ancillary, whether in relation to your participation in Dust Airdrop Campaign or the Project Group (including the Campaign Organiser) and/or the Ether Cards Ecosystem which are not specifically or explicitly contained in or stated in these Terms.

The Campaign Organiser reserves the right, in its sole and absolute discretion, to adopt organisational, technical or other measures to ensure that the Dust Airdrop Campaign be unavailable to certain persons that it may determine are ineligible to participate in Dust Airdrop Campaign, and none of the members of the Project Group (including the Campaign Organiser) and the Indemnified Persons shall be liable for any indirect, special, incidental, consequential or other losses of any kind, in tort, contract or otherwise (including but not limited to loss of revenue, income or profits, and loss of use or data), arising out of or in connection with any consequences of such prohibition.

Notwithstanding your acceptance of these Terms or anything to the contrary herein, the Campaign Organiser reserves the right, for any reason whatsoever (without having to account for such reason(s)) and in its sole and absolute discretion, to reject your participation in Dust Airdrop Campaign and none of the members of the Project Group (including the Campaign Organiser) and the Indemnified Persons shall be liable for any indirect, special, incidental, consequential or other losses of any kind, in tort, contract or otherwise (including but not limited to loss of revenue, income or profits, and loss of use or data), arising out of or in connection with any consequences of such rejection.

The Campaign Organiser reserves the right, in its sole and absolute discretion, to abort the Dust Airdrop Campaign and/or the Project at any time after your acceptance of these Terms. In the event of such abortion, you acknowledge and agree that none of the members of the Project Group (including the Campaign Organiser) shall have any obligation in any form or manner whatsoever to you, and none of the members of the Project Group (including the Campaign Organiser) and the Indemnified Persons shall be liable for any indirect, special, incidental, consequential or other losses of any kind, in tort, contract or otherwise (including but not limited to loss of revenue, income or profits, and loss of use or data), arising out of or in connection with any consequences of such abortion.

In the event that the Campaign Organiser discovers that you, in your participation in the Dust Airdrop Campaign, have engaged in unfair, excessive or abusive usage or conduct, the Campaign Organiser reserves the right to take such actions as may be necessary, to the fullest extent possible under law, to protect the Campaign Organiser from losses, damages, harm or degradation of any form and manner.

**LIMITATION OF LIABILITY AND INDEMNIFICATION**

To the maximum extent permitted by the Applicable Laws, regulations and rules:

1. the Project Group (including the Campaign Organiser) and the Indemnified Persons shall not be liable for any indirect, special, incidental, consequential or other losses of any kind, in tort, contract or otherwise (including but not limited to loss of revenue, income or profits, and loss of use or data), arising out of or in connection with your participation in Dust Airdrop Campaign;

1. without prejudice to the foregoing, you hereby agree that the aggregate liability of the members of the Project Group (including the Campaign Organiser), in tort, contract or otherwise, arising out of or in connection with the participation in the Dust Airdrop Campaign shall in any event be limited to US$100; and

1. you hereby agree to waive all rights to assert any claims against the Project Group (including the Campaign Organiser) and/or the Indemnified Persons under such Applicable Laws, regulations and rules.

To the maximum extent permitted by the Applicable Laws, regulations and rules, you shall indemnify, defend, and hold the Project Group (including the Campaign Organiser) and/or the Indemnified Persons harmless from and against any and all claims, damages, losses, suits, actions, demands, proceedings, expenses, and/or liabilities (including but not limited to reasonable legal fees incurred and/or those necessary to successfully establish the right to indemnification) filed/incurred by any third party against any member of the Project Group (including the Campaign Organiser) or the Indemnified Persons arising out of a breach by you of any warranty, representation, or obligation hereunder.

**NO ASSIGNMENT**

The Campaign Organiser may, at its sole and absolute discretion, assign its rights and/or delegate its duties under these Terms. You may not assign your rights or delegate your duties, and any assignment or delegation without the written consent of the Campaign Organiser, which the Campaign Organiser may withhold at its sole and absolute discretion, shall be void. Subject to these Terms, only you and no other person shall have the right to any claim against the Campaign Organiser in connection with your participation in Dust Airdrop Campaign. You shall not assign, trade or transfer, or attempt to assign, trade or transfer, your right to any such claim. Any such assignment or transfer shall not impose any obligation or liability of the Campaign Organiser to the assignee or transferee. The Campaign Organizer may assign right under these Terms to any person without notice to you.

**INTELLECTUAL PROPERTY RIGHTS**

These Terms shall not entitle you to any intellectual property rights, including the rights in relation to the use, for any purpose, of any information, image, user interface, logos, trademarks, trade names, Internet domain names or copyright in connection with your participation in the Dust Airdrop Campaign, the Project, the Website, the Dust Group, the Tokens and the Ether Cards Ecosystem.

There are no implied licences under these Terms, and any rights not expressly granted to you hereunder are reserved by the Campaign Organiser.

**INTELLECTUAL PROPERTY RIGHTS**

The pages of the Website and/or Channels may contain links to third-party websites and services which are owned and operated by third parties (&quot;**Third Party Online Products and Service(s)**&quot;). These links are provided for your information and convenience only, and are NOT an endorsement by the Campaign Organiser, its directors, officers, employees, agents, successors, and permitted assignees of the contents of such linked websites or third parties, over which none of the aforementioned entities have any control over. Your access to and use of any Third Party Online Products and Service is governed by the terms, conditions, disclaimers and notices found on each such website or in connection with such services. The Campaign Organiser has not verified, will not, and is under no obligation to verify the accuracy, suitability or completeness of the contents on such Third Party Online Products and Services, and the Campaign Organiser does not control, endorse, warrant, promote, recommend or in any way assume responsibility or liability for any services or products that may be offered by or accessed through such Third Party Online Products and Services or the operators of them, or the suitability or quality of any of such Third Party Online Products and Services. In addition, the Campaign Organiser does not warrant that such Third Party Online Products and Services or the software, data or files contained in, accessed via or linked or referred to in, such Third Party Online Products and Services are free of viruses (or other deleterious data or programs) or defects or that use of such Third Party Online Products and Services will not cause harm or that they conform or will conform with any user expectations. Furthermore, the Campaign Organiser is not responsible for maintaining any materials referenced from another website, and makes no warranties for that website or service in such context.

To the maximum extent permitted by all Applicable Laws, regulations and rules, none of the members of the Project Group (including the Campaign Organiser) shall be liable for any damage or loss of any kind, whether direct or indirectly, or in tort, contract or otherwise (including but not limited to loss of revenue, income or profits, and loss of use or data) or any other impact, directly or indirectly resulting from or relating to:

  1. the access to and/or use of any content, goods or services available on, from or through any Third Party Online Product and Service;

  1. the inability to access or the malfunction of any Third Party Online Product and Service;

  1. the loss, use or misuse of, or unauthorized access to, any data or personal or other financial information provided by you to any Third Party Online Product and Service;

  1. the failure by any Third Party Online Product and Service to complete any transaction for any reason;

  1. any failure or break of any Third Party Online Product and Service&#39;s security protections, mechanisms, protocol and/or procedures; and/or

  1. the direct or indirect use of any Third Party Online Service or any other website or service by other persons.

**NO WAIVER**

Any failure of the Campaign Organiser to enforce these Terms or to assert any right(s), claim(s) or causes of action against you under these Terms shall not be construed as a waiver of the right of the Campaign Organiser to assert any right(s), claim(s) or causes of action against you.

No provision of these Terms shall be considered waived unless such waiver is in writing and signed by the Party that benefits from the enforcement of such provision. No waiver of any provision in these Terms, however, will be deemed a waiver of a subsequent breach of such provision or a waiver of a similar provision. In addition, a waiver of any breach or a failure to enforce any term or condition of these Terms will not in any way affect, limit, or waive a Party&#39;s rights hereunder at any time to enforce strict compliance thereafter with every term and condition hereof.

**ENTIRE AGREEMENT**

These Terms contain the entire agreement and the understanding between the Parties and supersedes all prior agreements, understandings or arrangements (both oral and written) in relation to your participation in the Dust Airdrop Campaign.

**TAXES**

You shall be responsible for determining any tax applicable and payable to any taxation authority in respect of your participation in Dust Airdrop Campaign (&quot; **Payable Tax**&quot;) and declaring, withholding, collecting, reporting and remitting the correct amount of Payable Tax to the appropriate tax authorities. You shall be solely liable for all penalties, claims, fines, punishments, or other liabilities arising from the non-fulfilment or non-performance to any extent of any of your obligations in relation to the Payable Tax.

The Campaign Organiser shall not be responsible for determining any Payable Tax and declaring, withholding, collecting, reporting and remitting the correct amount of Payable Tax to the appropriate tax authorities.

**GOVERNING LAW AND DISPUTE RESOLUTION**

These Terms shall be governed by, and construed in accordance with, the laws of the Republic of Singapore.

Any dispute arising out of or in connection with these Terms, including any question regarding its existence, validity or termination, shall be referred to and finally be resolved by arbitration in Singapore in accordance with the rules of the SIAC for the time being in force, which rules are deemed to be incorporated by reference in this paragraph. The seat of the arbitration shall be Singapore. The tribunal shall consist of a sole arbitrator to be appointed by the Chairman of the SIAC. The language of the arbitration shall be English. This arbitration agreement shall be governed by the laws of the Republic of Singapore.

Each of the Parties irrevocably submits to the non-exclusive jurisdiction of the courts of Singapore to support and assist the arbitration process pursuant to Paragraph 12.2 of these Terms, including if necessary the grant of interlocutory relief pending the outcome of that process.

**CONTRACTS (RIGHTS OF THIRD PARTIES) ACT**

Subject to Paragraph 13.2, the Contracts (Rights of Third Parties) Act (Chapter 53B) of Singapore, as may be modified, amended or supplemented from time to time, shall apply to these Terms.

Save for the Indemnified Persons who shall have rights to the extent accorded thereto under these Terms, a person who is not a Party shall not have any rights whatsoever under these Terms or to enforce these Terms.

**SEVERANCE AND PARTIAL INVALIDITY**

If any of these Terms is rendered void, illegal or unenforceable by any legislation to which it is subject, it shall be rendered void, illegal or unenforceable to that extent and no further and, for the avoidance of doubt, the rest of these Terms shall continue to be valid and in full force and effectand shall in no way be affected, impaired or invalidated, and the Parties hereto shall use their commercially reasonable efforts to find and employ an alternative means to achieve the same or substantially the same result as that contemplated by such term, provision, covenant or restriction. It is hereby stipulated and declared to be the intention of the Parties that they would have executed the remaining terms, provisions, covenants and restrictions without including any of such that may be hereafter declared invalid, illegal, void or unenforceable.

The illegality, invalidity or unenforceability of any provision of these Terms under the law of any jurisdiction shall not affect its legality, validity or enforceability under the law of any other jurisdiction nor the legality, validity or enforceability of any other provision.

**ANNEX**

**DEFINITIONS**

In these Terms, the following words and expressions shall, where not inconsistent with the context, have the following meanings respectively:

&quot; **Affiliate**&quot;:

1. with respect to any person, means any other person directly or indirectly controlling, controlled by or under common control with such person; and

1. with respect to the Campaign Organiser, means any other person directly or indirectly controlling, controlled by or under common control with such person.

&quot; **Applicable Laws**&quot; means all relevant or applicable statutes, laws (including any reporting and/or withholding tax requirements of any government), rules, regulations, directives, circulars, notices, guidelines and practice notes of any nation or government, any state or other political subdivision thereof, any entity exercising legislative, executive, judicial or administrative functions of or pertaining to government, including, without limitation, any government authority, agency, department, board, commission or instrumentality, and any court, tribunal or arbitrator(s) of competent jurisdiction, and any self-regulatory organization, private bodies exercising quasi-governmental, regulatory or judicial-like functions to the extent they relate to you, the Project Group (including the Campaign Organiser) or the Dust Airdrop Campaign.

&quot; **Approvals**&quot; has the meaning ascribed to it in Paragraph 3.1(m).

&quot; **Channels**&quot; has the meaning ascribed to it in the Recitals.

&quot; **Claim Period**&quot; has the meaning ascribed to it in Paragraph 1.1(d).

&quot; **close associate**&quot; in relation to a senior foreign political figure means a person who is widely and publicly known to maintain an unusually close relationship with such senior foreign political figure, and includes a person who is in a position to conduct substantial domestic and international financial transactions on behalf of such senior foreign political figure.

&quot; **Designated Person / Entity**&quot; has the meaning ascribed to it in Paragraph 2.2(c).

&quot; **Dispute**&quot; means any dispute between the Parties arising out of or in relation to these Terms.

&quot; **Disqualified Person**&quot; has the meaning ascribed to it in Paragraph 2.2.

&quot; **Dust Airdrop Campaign**&quot; has the meaning ascribed to it in Paragraph 1.1.

&quot; **Ether Card NFTs**&quot; means the tiered-membership card NFTs associated with the Ether Cards Ecosystem which are further categorised by the following tiers known as &quot; **OG Ether Card**&quot;, &quot; **Alpha Ether Card**&quot; and &quot; **Founder Ether Card**&quot;.

&quot; **Eligible Participants**&quot; means a holder of an Ether Card NFT where (a) such Ether Card NFT is held by such holder during the Claim Period; (b) such Ether Card NFT has not been subject of any Claim for Allocated Airdrop Tokens in respect of such Ether Card NFT; and (c) such holder of the Ether Card NFT is not a Disqualified Person or a person who is prohibited to participate in the Dust Airdrop Campaign based on these Terms.

&quot; **Ether Cards Ecosystem**&quot; means the community-driven NFT platform with an associated protocol that allows creators to enhance the value of their NFT assets by creating and implementing use-cases and functionalities for such NFT assets as part of the Project, to be developed by the Campaign Organiser and/or its affiliate, using blockchain technology.

&quot; **Excluded Jurisdiction**&quot;means any of the following :

1. the People&#39;s Republic of China, the United States of America, Democratic People&#39;s Republic of Korea, Democratic Republic of Congo, Iran, Libya, Somalia, South Sudan, Sudan and Yemen;

1. a jurisdiction identified by the Financial Action Task Force (FATF) for strategic AML/CFT deficiencies and included in FATF&#39;s listing of &quot;High-risk and Other Monitored Jurisdictions&quot; accessible at http://www.fatf-gafi.org/publications/high-risk-and-other-monitored-jurisdictions; and

1. a jurisdiction (including but not limited to the Sanctioned Countries) in which the Dust Airdrop Campaign is prohibited, restricted or unauthorised in any form or manner whether in full or in part under the laws, regulatory requirements or rules in such jurisdiction.

&quot; **Foreign Bank**&quot; means a non-United States of America banking institution.

&quot; **immediate family**&quot; in relation to a senior foreign political figure includes such figure&#39;s parents, siblings, spouse, children and in-laws.

&quot; **Indemnified Persons**&quot; means the Campaign Organiser and each of the Campaign Organiser&#39;s Affiliate&#39;s subsidiaries, related companies, affiliates, directors, officers, employees, agents, service providers, successors, and permitted assignees.

&quot; **MAS**&quot; means the Monetary Authority of Singapore.

&quot; **Money Laundering Laws**&quot; has the meaning ascribed to it in Paragraph 3.1(o).

&quot; **NFT**&quot; means non-fungible tokens.

&quot; **OFAC**&quot; means the United States Office of Foreign Assets Control of the United States Department of the Treasury.

&quot; **Participant Token Address**&quot; has the meaning ascribed to it in Paragraph 1.1(b).

&quot; **Payable Tax**&quot; has the meaning ascribed to it in Paragraph 11.1.

&quot; **Project**&quot; means the Ether Cards project as described on the Website.

&quot; **Project Group**&quot; means the Campaign Organiser and its Affiliates.

&quot; **Sanctioned Country**&quot; has the meaning ascribed to it in Paragraph 3.1(n)(iii).

&quot; **Sanctions**&quot; has the meaning ascribed to it in Paragraph 3.1(n)(ii).

&quot; **senior foreign political figure**&quot; means a senior official in the executive, legislative, administrative, military or judicial branch of a government (whether elected or not), a senior official of a major political party, or a senior executive of a foreign government-owned corporation. It includes any corporation, business or other entity that has been formed by, or for the benefit of, a senior foreign political figure.

&quot;**Third Party Online Products and Service(s)**&quot; has the meaning ascribed to it in Paragraph 8.1.

&quot; **Tokens**&quot; means the Dust Tokens, a native cryptographic token created and issued by the Campaign Organiser or its Affiliate to be used as part of the Ether Cards Ecosystem as described on the Website, and &quot; **Token**&quot; shall be construed accordingly.

&quot; **Website**&quot; means the website of the Campaign Organiser which is accessible at https://ether.cards/.

In these Terms, a reference to:

1. the Campaign Organiser includes a reference to its duly authorised agents and/or delegates;

1. a person includes a reference to any individual, body corporate, unincorporated association or partnership;

1. an agreement or other document is a reference to that agreement or document as from time to time supplemented or amended;

1. the masculine gender includes the feminine and neuter genders and vice versa;

1. the singular includes the plural and vice versa; and

1. any law or regulation is a reference to that law or regulation as amended from time to time and, where the same has been repealed, to any re-enactment or replacement of the same.

`;

export default terms;