import React from "react";
import styled from "styled-components";

const AmountContainer = styled.div`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1rem;
  color: white;
  background: #0b122954;
  border-radius: 20px;
  padding: 25px 20px;
  text-align: center;
`;

const Amount = styled.span`
  font-family: "Believer";
`;

const DustAmount = ({ amount, text }) => {
  return (
    <AmountContainer>
      {text ? text : "Total Dust on your cards"}: &nbsp;
      <Amount>{amount ? amount : 0}</Amount>
    </AmountContainer>
  );
};

export default DustAmount;
