import React from "react";
import { PinkOutline } from "../../../components/Button";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";

const AmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1rem;
  color: grey;
  background: #0b122954;
  border-radius: 10px;
  padding: 15px 20px;
  margin: 5px 0;
  text-align: left;
`;

const StyledPinkOutline = styled(PinkOutline)`
  ${media.xs`
    max-width: 110px;
    min-width: 110px;
    white-space: normal;
  `}
  ${media.sm`
    max-width: 110px;
    min-width: 110px;
    white-space: normal;
  `}
  ${media.md`
    max-width: unset;
  `}
`;

const DustText = styled.div`
  align-self: center;
`;

const DustTextSmall = styled.div`
  align-self: center;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  ${media.xs`
    margin-right: 0;
  `}
  ${media.sm`
    margin-right: 0;
  `}
  ${media.md`
    margin-right: 20px;
  `}
`;
const AmountText = styled.span`
  font-family: "Believer";
  color: white;
  font-weight: 500;
  margin-right: 10px;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${media.xs`
    flex-direction: column-reverse;
    margin-left: 20px;
  `}
`;
const LineBreakResponsive = styled.br`
  display: none;
  ${media.xs`
  display: inline
`}
`;

const DustAmountClaimButton = ({
  text,
  buttonText,
  amount,
  onClick,
  claimLocked,
}) => {
  return (
    <AmountContainer>
      <DustText>
        {text || "Total Dust in your wallet"}: &nbsp;
        <LineBreakResponsive />
        <AmountText>{amount || "0"}</AmountText>
      </DustText>

      <ButtonContainer>
        {claimLocked && <DustTextSmall>Coming Soon</DustTextSmall>}
        <StyledPinkOutline
          disabled={claimLocked || Number(amount) == 0}
          onClick={onClick}
        >
          {buttonText || "Claim"}
        </StyledPinkOutline>
      </ButtonContainer>
    </AmountContainer>
  );
};

export default DustAmountClaimButton;
