import React, { useContext, useEffect, useState } from 'react';
import './AuthPage.css';
import Web3Ctx from '../Context/Web3Ctx';
import { toast } from 'react-toast';
import FooterComponent from '../Footer/FooterComponent';
import config from '../../config'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string';
import { parseRpcError } from '../Utils';
import { ECNav } from 'ec-commons';
import { ethers } from 'ethers';


const AuthPage = (props) => {
    const { onboard, address, ethersProvider } = useContext(Web3Ctx);
    const [isConnected, setIsConnected] = useState(false);
    const [isValidParams, setIsValidParams] = useState(false);
    const [connectedAddress, setIsConnectedAddress] = useState('0x0');
    const [cards, setCards] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cardArtwork, setCardArtwork] = useState('');
    const { search } = useLocation();

    const baseCid = config.BASE_CID;

    useEffect(() => {
        if (address) {
            setIsConnected(true);
            signMessage();
        } else {
            setIsConnected(false);
        }
    }, [address]);

    const signMessage = async () => {
        const values = queryString.parse(search)
        //console.log(values);
        if ('contract' in values && 'message' in values && 'id' in values) {
            setIsValidParams(true);
            try {
                let message = ethers.utils.toUtf8Bytes(values.message);
                let signer = ethersProvider.getSigner();
                let addr = await signer.getAddress();
                let signedMessage = await ethersProvider.send("personal_sign", [
                    ethers.utils.hexlify(message),
                    addr.toLowerCase()
                ]);
                if (signedMessage) {
                    fetch('https://authbot.ether.cards/receive_new_user/' + values.contract, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            wallet_addr: address,
                            message: signedMessage,
                            id: values.id
                        })
                    })
                } else {
                    console.error('Some error occured! : message not found!')
                }
            } catch (e) {
                console.error(e);
                toast.error(e);
            }
        } else {
            setIsValidParams(false);
        }

    }

    return (
        <>
            <ECNav projectUrl={config.APP_BASE_URL} onboard={onboard} address={address} />

            <div id="walletContainer" className="container">
                <div className="row padd">
                    <div className="col-lg-12 mt-5">

                        <h2 className="text-center text-peach">Owner verification.</h2>

                        <div className="row">
                            <div className="col-md-8 mx-auto text-left mt-5 mb-3">
                                <p>By signing the message from you're wallet you are proving that you have ownership to your wallet and therefore the NFTs inside it.</p>
                            </div>
                        </div>


                        {isConnected ?

                            <div className="row">
                                <div className="col-md-6 mx-auto mt-5 text-center">
                                    {isValidParams ?
                                        <h5>Please sign the message to verify your ownership.</h5>
                                        :
                                        <h5>Invalid url.</h5>
                                    }
                                </div>
                            </div>

                            :

                            <div className="row">
                                <div className="col-md-4 mx-auto mt-5 text-center">
                                    <h5>Please connect your wallet to verify your ownership.</h5>

                                    <button className="btn btn-peach btn-outline round mx-auto mt-5 px-3" onClick={() => { onboard.walletSelect() }}>CONNECT</button>
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </>
    );
}

export default AuthPage;