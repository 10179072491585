import { utils } from "ethers"

export const CHAIN_INFO = {
    1: {
        CHAIN_ID_HEX: "0x1",
        CHAIN_ID_DEC: 1,
        CHAIN_NAME: "Ethereum Mainnet",
        NATIVE_CURRENCY: {
            name: "ETH",
            symbol: "eth",
            decimals: 18,
        },
        RPC_URL: "https://mainnet.infura.io/v3/0a0bbd3ce4ea4be5ad706514cf2cd8cc",
        BLOCK_EXPLORER_URLS: "https://etherscan.io",
    },
    137: {
        CHAIN_ID_HEX: "0x89",
        CHAIN_ID_DEC: 137,
        CHAIN_NAME: "Polygon Network",
        NATIVE_CURRENCY: {
            name: "MATIC",
            symbol: "matic",
            decimals: 18,
        },
        RPC_URL: "https://polygon-mainnet.infura.io/v3/0a0bbd3ce4ea4be5ad706514cf2cd8cc",
        BLOCK_EXPLORER_URLS: "https://polygonscan.com",
    },
};

export const PUBLIC_CHAIN_INFO = {
    137: {
        chainId: utils.hexValue(137), // '0x89'
        chainName: "Polygon",
        nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
        rpcUrls: ["https://polygon-rpc.com"],
        blockExplorerUrls: ["https://www.polygonscan.com/"],
    }
}

export default CHAIN_INFO