import styled from "styled-components";
import { Container } from "styled-bootstrap-grid";
import { RowJustify } from "../../../components/Row";
import { PinkOutline } from "../../../components/Button";
import blockedCountries from "../../../constants/blockedCountries";
import { ColAlign, Col } from "../../../components/Col";
import { LinkPink } from "../../../components/Link";

const Wrapper = styled.div`
  padding: 100px 0;
  position: fixed;
  display: ${({ show }) => (show ? "visible" : "none")};
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.9);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  overflow: scroll;
  color: white;
  font-weight: 400;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 30px;
`;

const Text = styled.p`
  text-align: center;
`;

const Country = styled.div`
  color: #9a989a;
  &::before {
    content: "• ";
  }
`;

const StyledPinkOutline = styled(PinkOutline)`
  margin-top: 50px;
`;

const RestrictedCountries = ({ show, hide }) => {
  return (
    <Wrapper show={show}>
      <Container>
        <Title>Restricted Region</Title>
        <Text>
          Unfortunately Dust Claims from your region are restricted, please see
          restricted regions below:
        </Text>

        <ColAlign col={12} md={10} lg={8}>
          <RowJustify justify="left">
            <div style={{ background: "black" }}></div>
            {Object.values(blockedCountries).map((country) => {
              return (
                <Col key={country} align="center" col={4}>
                  <Country>{country}</Country>
                </Col>
              );
            })}
          </RowJustify>
        </ColAlign>

        <Text style={{ marginTop: "20px" }}>
          For more information or any questions, please contact a moderator in
          the{" "}
          <LinkPink
            href="https://discord.com/channels/807946276079140875/811206939701936129"
            target="_blank"
          >
            Ether Cards Discord
          </LinkPink>{" "}
          Channel.
        </Text>

        <RowJustify justify="center">
          <StyledPinkOutline onClick={() => hide()}>Back</StyledPinkOutline>
        </RowJustify>
      </Container>
    </Wrapper>
  );
};

export default RestrictedCountries;
