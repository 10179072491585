import { BitArray } from "@ethercards/ec-util";
import { ethers } from "ethers";
import { Zoom } from "zoom-next";
import cliTable from 'cli-table';

export function displayGiveawayInfo(_data) {

    const setTypes = {
        "1": "Data",
        "2": "Range",
        "3": "Range + Data",
    }

    // console.log("");
    // console.log("---------------- Giveaway Info Start -----------------------------------------------------------------");
    // console.log("");
    // console.log("  name:                ", ethers.utils.parseBytes32String(_data.giveaway._name));
    // console.log("  distributor:         ", _data.giveaway._distributor);
    // console.log("  winner count:        ", _data.giveaway._winnerCount);
    // console.log("  started:             ", _data.giveaway._started);
    // console.log("  processed:           ", _data.giveaway._processed);
    // console.log("  hash:                ", _data.giveaway._hash);
    // console.log("  _random:             ", _data.giveaway._random.toString());
    // console.log("  set count:           ", _data.setInfo.length);

    // console.log("");
    // const table = new cliTable({
    //     head: ['Num', 'G_ID', 'Name', 'Type', 'Chances', 'Size', 'Start', 'End', 'Implementer'], colWidths: [6, 6, 34, 20, 10, 10, 10, 10, 50]
    // });

    // for (let i = 0; i < _data.setInfo.length; i++) {
    //     const setInfo = _data.setInfo[i];

    //     table.push(
    //         [
    //             i,
    //             setInfo.id,
    //             ethers.utils.parseBytes32String(setInfo.name),
    //             setInfo.setType.toString() + " - " + setTypes[setInfo.setType.toString()],
    //             setInfo.permille.toString(),
    //             setInfo.size.toString(),
    //             setInfo.start.toString(),
    //             setInfo.end.toString(),
    //             setInfo.implementer,
    //         ]
    //     );
    // }

    // console.log(table.toString());


    if (_data.winningTokens) {

        // console.log("  winners.length:      ", _data.winningTokens.length);
        // console.log("  winners:             ", _data.winningTokens.join(","));
        // console.log("  claimers.length:     ", _data.claimedTokens.length);
        // console.log("  claimers:            ", _data.claimedTokens.join(","));

        return { winners: _data.winningTokens, claimers: _data.claimedTokens, name: ethers.utils.parseBytes32String(_data.giveaway._name) }
    }

    // console.log("----------------- Giveaway Info End -----------------------------------------------------------------");
}

function getEnabledIdsFromBitArray(_data) {
    const BitArrayData = BitArray.fromUint8Array(_data);
    const results = [];
    Object.keys(BitArrayData.toEnabled()).forEach((_value, _index) => {
        results.push(parseInt(_value));
    });
    return results;
}


export async function getGiveawayInfos(count, contract, zoomContract) {

    const ZoomLibraryInstance = new Zoom();
    const giveawayInfos = [];
    const item_identifiers = [];
    let callNum = 0;

    for (let i = 1; i <= count; i++) {
        // console.log(i)
        // request the token ID
        const tokenIdCall = ZoomLibraryInstance.addCall(
            // the contract we're calling
            contract,
            ["getGiveawayInfo", [i]],
            // signature used to decode the result
            "getGiveawayInfo(uint16) returns (tuple)"
        );
        item_identifiers.push({ id: i, call: tokenIdCall });
        callNum++;
    }

    // Prepare the binary call
    const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();
    console.time(`zoomCall_getGiveawayInfos_${contract.address}`);
    const combinedResult = await zoomContract.combine(ZoomQueryBinary);
    console.timeEnd(`zoomCall_getGiveawayInfos_${contract.address}`);
    ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);

    for (let i = 0; i < callNum; i++) {
        let giveawayDataFull = ZoomLibraryInstance.decodeCall(
            item_identifiers[i].call
        )
        const { winners, claimers, name } = displayGiveawayInfo(giveawayDataFull[0]);

        giveawayInfos.push({ winners, claimers, name, id: item_identifiers[i].id });
    }
    return giveawayInfos;
}
