const dev = {
    DEPLOYED_NTW_NAME: 'rinkeby',
    DEPLOYED_CHAIN_ID: 4,
    INFURA_ID: 'ef6166b767dc4d379b02be5c29d59f30',
    FORTMATIC_KEY: 'pk_test_DD2BBA8AAA1D4FED',
    RPC_URL: 'https://rinkeby.infura.io/v3/ef6166b767dc4d379b02be5c29d59f30',

    BASE_SERVER: 'https://heroku-staging.ether.cards',
    BASE_CID: `https://heroku-staging.ether.cards/card`,

    CARD_UPGRADE_URL: 'https://ec-card-upgrade.burneth.com',
    CARD_SWAP_URL: 'https://ec-swap.burneth.com',
    GOOD_COMPANY_URL: 'https://ec-good-company.burneth.com',

    ETHERSCAN_URL: 'https://rinkeby.etherscan.io/',
    OPENSEA_ASSET_URL: "https://testnets.opensea.io/assets/"
};

const prod = {
    DEPLOYED_NTW_NAME: 'mainnet',
    DEPLOYED_CHAIN_ID: 1,
    INFURA_ID: 'ef6166b767dc4d379b02be5c29d59f30',
    FORTMATIC_KEY: 'pk_live_FBFF1F05F2879F29',
    RPC_URL: 'https://mainnet.infura.io/v3/ef6166b767dc4d379b02be5c29d59f30',

    BASE_SERVER: 'https://heroku.ether.cards',
    BASE_CID: `https://heroku.ether.cards/card`,

    CARD_UPGRADE_URL: 'https://card-upgrade.ether.cards',
    CARD_SWAP_URL: 'https://swap.ether.cards',
    GOOD_COMPANY_URL: 'https://good-company.ether.cards',

    ETHERSCAN_URL: 'https://etherscan.io/',
    OPENSEA_ASSET_URL: "https://opensea.io/assets/"
};

const common = {
    MIN_ID: 0,
    MAX_ID: 9999,
    APP_BASE_URL: 'https://explorer.ether.cards',
    LAYERS_BASE_URL: 'https://ether-cards.mypinata.cloud/ipfs/Qmcm7BjsmhwWVA611EZSGkxcqt3JmsbF9m37kPNhDLoy4o',
    POLYGON_RPC: 'https://polygon-mainnet.infura.io/v3/ef6166b767dc4d379b02be5c29d59f30'
}

// if use npm/yarn start,  NODE_ENV = "development"
// if use npm/yarn build,  NODE_ENV = "production"
let envConfig = prod// process.env.NODE_ENV === "development" ? dev : prod
let config = { ...envConfig, ...common }

export default config;


// pointless comment for test commit
