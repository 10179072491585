import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  useZoomContract,
  useGiveawayRegistryContract,
} from "../../../hooks/useContract";
import Web3Ctx from "../../../components/Context/Web3Ctx";
import { PinkOutline, ButtonText } from "../../../components/Button";
import styled from "styled-components";
import { Row } from "../../../components/Row";
import { ColAlign } from "../../../components/Col";
import { getGiveawayInfos } from "../util";
import { media } from "styled-bootstrap-grid";
import giftIcon from "../../../assets/images/gift-icon.png";
import { Link } from "react-router-dom";

const Title = styled.p`
  font-weight: 700 !important;
  font-size: 1.4rem;
  color: white !important;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;

const Wrapper = styled.div`
  text-align: center;
  margin-top: 1.5rem;
`;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.2rem;
  color: grey;
  background: #0b122954;
  border-radius: 10px;
  padding: 15px 20px;
  margin: 5px 0;
  text-align: left;
`;

const Text = styled.p`
  margin-top: 10px;
  color: grey;
  font-weight: 500;
  line-height: 2rem;
`;
const TextSmall = styled(Text)`
  font-size: 0.9rem;
  margin-top: 30px;
`;

const DustText = styled.div`
  align-self: center;
`;
const AmountText = styled.span`
  color: white;
  font-weight: 500;
  margin-right: 10px;
`;
const GiftTextSmall = styled.div`
  align-self: center;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  ${media.xs`
    margin-right: 0;
  `}
  ${media.sm`
    margin-right: 0;
  `}
  ${media.md`
    margin-right: 20px;
  `}
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${media.xs`
    flex-direction: column-reverse;
    margin-left: 20px;
  `}
`;

const LineBreakResponsive = styled.br`
  display: none;
  ${media.xs`
  display: inline
`}
`;

const StyledPinkOutline = styled(PinkOutline)`
  ${media.xs`
    max-width: 110px;
    min-width: 110px;
    white-space: normal;
  `}
  ${media.sm`
    max-width: 110px;
    min-width: 110px;
    white-space: normal;
  `}
  ${media.md`
    max-width: unset;
  `}
`;

const Icon = styled.img`
  height: 28px;
  width: 28px;
  padding: 3px 2px;
  border-radius: 4px;
  background: #f51bdc;
`;

const GiftWithdrawal = ({ tokenIds, showTnc, setGiftIds }) => {
  const { ethersProvider, chainId } = useContext(Web3Ctx);
  const [giftAmount, setGiftAmount] = useState("0");
  const [claimLocked, setClaimLocked] = useState(false);

  const giftContract = useGiveawayRegistryContract();
  const zoomContract = useZoomContract();

  useEffect(() => {
    if (giftContract) {
      if (tokenIds && tokenIds.length > 0) {
        fetchGiftWinners(tokenIds);
      }
    }
  }, [giftContract, tokenIds]);

  const fetchGiftWinners = async (tokenIds) => {
    const filteredIds = tokenIds.filter((tokenId) => Number(tokenId) >= 10);
    let totalCanClaim = [];

    const giveawayCount = await giftContract.giveawayCount();

    const giveawayInfos = await getGiveawayInfos(
      giveawayCount,
      giftContract,
      zoomContract
    );

    giveawayInfos.forEach((giveawayInfo) => {
      const { winners, claimers } = giveawayInfo;
      console.log(winners);

      const filteredWinners = winners.filter((winner) =>
        filteredIds.includes(winner)
      );
      const canClaim = filteredWinners.filter(
        (winner) => !claimers.includes(winner)
      );

      // console.log(canClaim)
      totalCanClaim = totalCanClaim.concat(canClaim);
    });

    setGiftAmount(totalCanClaim.length);
    if (setGiftIds && typeof setGiftIds == "function") {
      setGiftIds([...new Set(totalCanClaim)]);
    }
  };

  return (
    <Wrapper>
      <Title>Gift</Title>
      <Row>
        <ColAlign col={12} md={10} lg={8} align="center">
          <Text>
            You can claim each drop from all of your cards in one single
            transaction or choose an individual card you want to withdraw from
            by selecting it and clicking on the <Icon src={giftIcon} /> icon,
            then clicking Claim.
          </Text>
          {/* {!claimLocked && (
            <TextSmall>
              Note: The following{" "}
              <ButtonText onClick={() => showTnc()}>
                Term and Conditions
              </ButtonText>{" "}
              apply for claiming Dust.
            </TextSmall>
          )} */}
        </ColAlign>

        <ColAlign col={12} md={12} lg={9} align="center">
          <AmountContainer>
            <DustText>
              {"Total Gifts on your cards"}: &nbsp;
              <LineBreakResponsive />
              <AmountText>{giftAmount || "0"}</AmountText>
            </DustText>

            <ButtonContainer>
              {claimLocked && <GiftTextSmall>Coming Soon</GiftTextSmall>}
              <StyledPinkOutline
                disabled={claimLocked || Number(giftAmount) == 0}
                as={Link}
                to={{
                  pathname: `/wallet/gift`,
                  tokenIds: tokenIds,
                  giftAmount: giftAmount,
                }}
              >
                CLAIM All GIFTS
              </StyledPinkOutline>
            </ButtonContainer>
          </AmountContainer>
        </ColAlign>
      </Row>
    </Wrapper>
  );
};

export default GiftWithdrawal;
