import styled, { keyframes } from "styled-components";
import DustHexagon from "../../assets/images/dust-hexagon.png";

const spinAnimation = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

const SpinningImage = styled.img`
  animation-name: ${spinAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  align-self: center;
`;

const SpinningDustHexagon = ({ height, width, style }) => {
  return (
    <SpinningImage
      height={height}
      width={width}
      src={DustHexagon}
      style={style}
    ></SpinningImage>
  );
};

export default SpinningDustHexagon;
