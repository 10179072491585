import { useEffect, useState, useMemo } from "react";

import Web3Ctx from "../Context/Web3Ctx.js";
import config from "../../config";
import { SupportedChainId } from "../../abi/constants/chain.js";
import CHAIN_INFO, { PUBLIC_CHAIN_INFO } from "./Constants";

import Onboard from "bnc-onboard";
import { ethers } from "ethers";
import { SpinnerDotted } from "spinners-react";

const DEPLOYED_CHAIN_ID = config.DEPLOYED_CHAIN_ID;
const FORTMATIC_KEY = config.FORTMATIC_KEY;
const RPC_URL = config.RPC_URL;

const Web3Manager = ({ children }) => {
  const [onboard, setOnboard] = useState(null);
  const [address, setAddress] = useState(null);
  const [wallet, setWallet] = useState(null);

  // Wallet Provider
  // Defaults to default if wallet not connected
  const [ethersProvider, setEthersProvider] = useState(null);
  // Deployed Provider
  const [defaultProvider, setDefaultProvider] = useState(null);

  // Init all supported chain providers
  const providers = useMemo(() => {
    return Object.keys(CHAIN_INFO).reduce((accumulator, chainId) => {
      accumulator[Number(chainId)] = new ethers.providers.JsonRpcProvider(
        CHAIN_INFO[chainId].RPC_URL
      );
      return accumulator;
    }, {});
  }, []);

  const [chainId, setChainId] = useState(DEPLOYED_CHAIN_ID);
  const [connecting, setConnecting] = useState(false);
  const [initDone, setInitDone] = useState(false);

  const isCorrectNetwork = chainId === DEPLOYED_CHAIN_ID;

  useEffect(() => {
    console.log("app mounted");
    const initApp = async () => {
      setInitDone(false);
      try {
        const onboard = Onboard({
          networkId: DEPLOYED_CHAIN_ID, // [Integer] The Ethereum network ID your Dapp uses.
          darkMode: true,
          blockPollingInterval: 12000,
          walletSelect: {
            wallets: [
              { walletName: "metamask" },
              { walletName: "coinbase" },
              { walletName: "trust", rpcUrl: RPC_URL },
              { walletName: "authereum" },
              { walletName: "wallet.io", rpcUrl: RPC_URL },
              { walletName: "atoken" },
              {
                walletName: "fortmatic",
                apiKey: FORTMATIC_KEY,
              },
              {
                walletName: "walletConnect",
                rpc: {
                  1: CHAIN_INFO[SupportedChainId.MAINNET].RPC_URL,
                  137: CHAIN_INFO[SupportedChainId.POLYGON].RPC_URL,
                },
              },
              { walletName: "opera" },
              { walletName: "operaTouch" },
              { walletName: "torus" },
              { walletName: "status" },
              { walletName: "walletLink", rpcUrl: RPC_URL },
              {
                walletName: "trezor",
                appUrl: "ether.cards",
                email: "info@ether.cards",
                rpcUrl: RPC_URL,
              },
              {
                walletName: "ledger",
                rpcUrl: RPC_URL,
              },
            ],
          },
          walletCheck: [
            { checkName: "derivationPath" },
            { checkName: "accounts" },
            { checkName: "connect" },
            { checkName: "network" },
          ],

          subscriptions: {
            wallet: (obWallet) => {
              // console.log('wallet on select',obWallet)
              setWallet(obWallet);
            },
            address: (obAddress) => {
              setAddress(obAddress);
            },
            network: (network) => {
              setChainId(network);
              console.log(network);

              if (network !== DEPLOYED_CHAIN_ID) {
                setDefaultProvider(
                  new ethers.providers.JsonRpcProvider(RPC_URL)
                );
              } else {
                setDefaultProvider(null);
              }
            },
          },
        });

        const savedWallet = localStorage
          ? localStorage.getItem("selectedWallet")
          : null;

        let isEmbed = document.location.hash
          ? document.location.hash.indexOf("embed") > -1
          : false;
        console.log(document.location, isEmbed);

        if (savedWallet && !isEmbed) {
          setConnecting(true);
          await onboard.walletSelect(savedWallet);
          const userReady = await onboard.walletCheck();
          //console.log('user ready(with saved wallet)',userReady);
        } else {
          const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
          setEthersProvider(provider);
        }

        setOnboard(onboard);
      } catch (e) {
        //ugly workaround
        setChainId(DEPLOYED_CHAIN_ID);
        const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
        setEthersProvider(provider);
        setInitDone(true);
        console.log("onboard init error", e);
      }
    };

    initApp();
  }, []);

  useEffect(() => {
    if (onboard && wallet && wallet.name && chainId) {
      checkWallet(onboard, chainId, wallet);
    } else {
      setConnecting(false);
      if (ethersProvider) {
        setInitDone(true);
      }
    }
  }, [wallet, chainId, onboard]);

  useEffect(() => {
    if (ethersProvider) {
      subscribeNetwork(ethersProvider);
    }
  }, [ethersProvider]);

  useEffect(() => {
    if (address && isWalletConnected() == false) {
      console.log("set address null");
      setAddress(null);
    }
  }, [address]);

  const subscribeNetwork = async (provider) => {
    const network = await provider.getNetwork().catch((e) => {
      console.log("error:", e);
    });
    if (network) {
      setChainId(network.chainId);
    }
  };

  const checkWallet = async (onboard, chainId, wallet) => {
    if (window.localStorage) {
      window.localStorage.setItem("selectedWallet", wallet.name);
    }
    const userReady = onboard.walletCheck();

    const newProvider = new ethers.providers.Web3Provider(
      wallet.provider,
      "any"
    );
    setEthersProvider(newProvider);
    console.log(
      "wallet,chainId, address, ethersProvider",
      wallet,
      chainId,
      address,
      newProvider
    );
    setInitDone(true);
    setConnecting(false);
  };

  const isWalletConnected = () => {
    console.log("check wallet state");
    if (onboard) {
      const state = onboard.getState();
      return state.wallet.name != null;
    } else return null;
  };

  const handleConnect = async (e) => {
    if (!initDone) return;
    if (onboard) {
      e.stopPropagation();
      console.log("reset");
      onboard.walletReset();
      console.log("walletSelect");
      await onboard.walletSelect();
    }
  };

  const handleDisconnect = () => {
    if (onboard) {
      console.log("logout wallet");
      onboard.walletReset();
      if (localStorage) {
        localStorage.removeItem("selectedWallet");
      }
    }
  };

  const getProvider = (contractChainId) => {
    if (contractChainId != null) {
      if (contractChainId == DEPLOYED_CHAIN_ID || contractChainId == chainId) {
        return ethersProvider;
      } else {
        if (providers[contractChainId]) {
          return providers[contractChainId];
        } else {
          const provider = new ethers.providers.JsonRpcProvider(
            CHAIN_INFO[contractChainId].RPC_URL
          );
          return provider;
        }
      }
    } else if (isCorrectNetwork) {
      return ethersProvider;
    } else return defaultProvider;
  };

  const switchNetwork = (chainId) => {
    try {
      const onboard = Onboard({
        networkId: chainId,
        darkMode: true,
        blockPollingInterval: 12000,
        walletSelect: {
          wallets: [
            { walletName: "metamask" },
            { walletName: "coinbase" },
            { walletName: "trust", rpcUrl: CHAIN_INFO[chainId].RPC_URL },
            { walletName: "authereum" },
            { walletName: "wallet.io", rpcUrl: CHAIN_INFO[chainId].RPC_URL },
            { walletName: "atoken" },
            {
              walletName: "fortmatic",
              apiKey: FORTMATIC_KEY,
            },
            {
              walletName: "walletConnect",
              rpc: {
                1: CHAIN_INFO[SupportedChainId.MAINNET].RPC_URL,
                137: CHAIN_INFO[SupportedChainId.POLYGON].RPC_URL,
              },
            },
            { walletName: "opera" },
            { walletName: "operaTouch" },
            { walletName: "torus" },
            { walletName: "status" },
            { walletName: "walletLink", rpcUrl: CHAIN_INFO[chainId].RPC_URL },
            {
              walletName: "trezor",
              appUrl: "ether.cards",
              email: "info@ether.cards",
              rpcUrl: CHAIN_INFO[chainId].RPC_URL,
            },
            {
              walletName: "ledger",
              rpcUrl: CHAIN_INFO[chainId].RPC_URL,
            },
          ],
        },
        walletCheck: [
          { checkName: "derivationPath" },
          { checkName: "accounts" },
          { checkName: "connect" },
          { checkName: "network" },
        ],

        subscriptions: {
          wallet: (obWallet) => {
            console.log("wallet on select", obWallet);
            setWallet(obWallet);
          },
          address: (obAddress) => {
            setAddress(obAddress);
          },
          network: (network) => {
            setChainId(network);
            console.log(network);

            if (network !== DEPLOYED_CHAIN_ID) {
              setDefaultProvider(new ethers.providers.JsonRpcProvider(RPC_URL));
            } else {
              setDefaultProvider(null);
            }
          },
        },
      });

      setOnboard(onboard);

      const previouslySelectedWallet =
        window.localStorage.getItem("selectedWallet");

      if (previouslySelectedWallet && onboard) {
        onboard.walletSelect(previouslySelectedWallet);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // Only for MetaMask
  // Not possible to use this with current version of onboard
  // const addNetwork = async (chainId) => {
  //   console.log(wallet);
  //   if (wallet && wallet.name == "MetaMask" && isWalletConnected()) {
  //     const chainInfo = [PUBLIC_CHAIN_INFO[chainId]];
  //     if (!chainInfo) return null;
  //     wallet.provider
  //       .request({
  //         method: "wallet_addEthereumChain",
  //         params: chainInfo,
  //       })
  //       .then((r) => {
  //         console.info(r);
  //       })
  //       .catch((e) => {
  //         console.error("Could not add chain", e);
  //       });
  //   }
  // };

  if (!initDone) {
    return (
      <div className="row" style={{ height: "100%", weight: "100%" }}>
        <div className="col m-auto text-center">
          <SpinnerDotted
            className="mr-2"
            size={40}
            thickness={160}
            speed={100}
            color="#ad33a1"
          />
        </div>
      </div>
    );
  }

  return (
    <Web3Ctx.Provider
      value={{
        onboard,
        wallet,
        address,
        getProvider,
        ethersProvider,
        defaultProvider,
        chainId,
        defaultChainId: config.DEPLOYED_CHAIN_ID,
        connecting,
        handleConnect,
        handleDisconnect,
        isCorrectNetwork,
        switchNetwork,
      }}
    >
      {children}
    </Web3Ctx.Provider>
  );
};

export default Web3Manager;
