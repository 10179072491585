import { useEffect, useState } from 'react';

import Web3Manager from './components/Web3Manager/Web3Manager';
import FooterComponent from './components/Footer/FooterComponent';
import PrefooterComponent from './components/Prefooter/PrefooterComponent';
import routes from './Routes';
import './App.css';

import { HashRouter, Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toast'

function App(props) {
  return (
    <Web3Manager>
      <div id="application">
        <HashRouter basename='/'>
          <Switch>
            {routes.map((route, key) => (
              <Route key={key} path={route.path} component={route.component} exact={route.exact} />
            ))}
          </Switch>
          <Footer />
        </HashRouter>
        <ToastContainer delay={4000} position="bottom-right" />
      </div>
    </Web3Manager>
  );
}

const Footer = () => {
  let location = useLocation();
  const [hide, setHide] = useState(false)
  useEffect(() => {
    if (location.pathname && location.pathname.includes("embed/")) {
      setHide(true)
    } else
      setHide(false)
  }, [location]);

  return (
    <>{!hide &&
      <footer>
        <PrefooterComponent />
        <FooterComponent />
      </footer>
    }</>
  )
}

export default App;
