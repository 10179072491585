import Box from "../../assets/images/box.png";
import CardLeft from "../../assets/images/card_left.png";
import CardMiddle from "../../assets/images/card_middle.png";
import CardRight from "../../assets/images/card_right.png";
import CardTop from "../../assets/images/card_top.png";
import styled, { keyframes } from "styled-components";
import { media } from "styled-bootstrap-grid";

const floatAnimation = keyframes`
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px);
    }
    100% {
        transform: translatey(0px);
    }
`;

const glowAnimation = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
`;

const Wrapper = styled.div`
  position: relative;
  margin: auto;
  ${media.xs`
    height: 300px;
    width: 300px;
  `}
  ${media.sm`
    height: 350px;
    width: 350px;
  `}
  ${media.md`
    height: 350px;
    width: 350px;
  `}
`;
const BaseImage = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  animation-name: ${glowAnimation};
  animation-duration: 6s;
  animation-iteration-count: infinite;
`;

const CardLeftImage = styled.img`
  height: 20%;
  position: absolute;
  left: -40%;
  right: 0;
  top: 30%;
  margin: auto;
  animation-name: ${floatAnimation};
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
`;
const CardRightImage = styled.img`
  height: 20%;
  position: absolute;
  top: -50%;
  left: 0%;
  right: -30%;
  bottom: 0;
  margin: auto;
  animation-name: ${floatAnimation};
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
`;
const CardMiddleImage = styled.img`
  height: 24%;
  position: absolute;
  left: 0;
  right: 0;
  top: 10%;
  bottom: 0;
  margin: auto;
  animation-name: ${floatAnimation};
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-delay: 0s;
`;
const CardTopImage = styled.img`
  height: 20%;
  position: absolute;
  left: -15%;
  right: 0;
  top: 5%;
  margin: auto;
  animation-name: ${floatAnimation};
  animation-duration: 9s;
  animation-iteration-count: infinite;
  animation-delay: 4s;
`;

const FloatingBoxCards = () => {
  return (
    <Wrapper>
      <BaseImage src={Box}></BaseImage>
      <CardLeftImage src={CardLeft}></CardLeftImage>
      <CardRightImage src={CardRight}></CardRightImage>
      <CardTopImage src={CardTop}></CardTopImage>
      <CardMiddleImage src={CardMiddle}></CardMiddleImage>
    </Wrapper>
  );
};

export default FloatingBoxCards;
