import { SupportedChainId } from './chain'

export const ZOOM_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x7cdF091AF6a9ED75E3192500d3e5BB0f63e22Dea",
    [SupportedChainId.RINKEBY]: "0x491592F30D9a3d1887F486eA2A3c72ad82fAcF4D"
}

export const EC_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x97CA7FE0b0288f5EB85F386FeD876618FB9b8Ab8",
    [SupportedChainId.RINKEBY]: "0xAAD4475343f5150E33d6194270f04e7e5968A2f8"
}

export const USER_TOKEN_ID_REGISTRY_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0xB57fba975C89492B016e0215E819B4d489F0fbcD",
    [SupportedChainId.RINKEBY]: "0x3c60a66399475E25189F77d36593ED0FB032226E"
}

export const DUST_DISPENSER_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0xCF01E438E6bC82653a65273f215Ae5e5D19B1B33",
    [SupportedChainId.RINKEBY]: "0x8b58BcE91781a7C978b004c6F55eE5B9E423beB2"
}

export const DUST_DISPENSER_V2_ADDRESSES = {
    [SupportedChainId.RINKEBY]: "0x312C65aFEd7684B185e17c4d718843c3C517AC0d",
    [SupportedChainId.POLYGON]: "0xe41b7c9b08ecc127670ea0dd2ed122277880612c"
}

export const DUST_POOL_EXTRA_ALLOCATOR_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0xcC45A0765E4FCfe174ae6BA7C4F20E1fA3beDb4d",
    [SupportedChainId.RINKEBY]: "0x8B78fdEdC87166CC1E274aAe0AAD660598761c14"
}

export const DUST_TOKEN_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0xe2E109f1b4eaA8915655fE8fDEfC112a34ACc5F0",
    [SupportedChainId.RINKEBY]: "0x30189f1D90e1410176619020C4D6438A40A8626d",
    [SupportedChainId.POLYGON]: "0x167ef3603c0b1d52be9a96e3af01aa33097a6ce9"
}

export const PUNKS_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x1098Dc8e1F79bfDD3A1e10022A69c2f094A6F2e5",
    [SupportedChainId.RINKEBY]: "0x46B498Be974e85c8AD2142b6aB4Bb78B68F62B96"
}

export const MEEBITS_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7",
    [SupportedChainId.RINKEBY]: "0xD2dB7e45Bf931965d552C60646AbB3A495f3D88a"
}

export const TYSONS_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x40fb1c0f6f73b9fc5a81574ff39d27e0ba06b17b",
    [SupportedChainId.RINKEBY]: "0xe10e436CB58D2308c9b2F63FF19E87c32a0DC284"
}

export const GIVEAWAY_REGISTRY_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0xEFe9C015CFF1E9Bd1d7Cc1782B13571eA77cf63b",
    [SupportedChainId.RINKEBY]: "0xf3ADcB8C3b4c7BDCc307c28e98694C3d6085016e"
}

export const DIRTY_ROBOT_TOKEN_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x922a6ac0f4438bf84b816987a6bbfee82aa02073",
    [SupportedChainId.RINKEBY]: "0x1ea532d2a9b44479a77cdf1e5b724ccbe8c47b16"
}

export const GENE_EC_ALLOCATOR_ADDRESSES = {
    [SupportedChainId.POLYGON]: "0xEFe9C015CFF1E9Bd1d7Cc1782B13571eA77cf63b"
}