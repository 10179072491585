import React, { useEffect, useState, useContext, useMemo } from "react";
import closeX from "../../../assets/images/nft-icons/close_button.svg";
import styled from "styled-components";
import { DustAmountEmbedButton } from ".";
import giftIcon from "../../../assets/images/gift-icon.png";
import { PinkOutline, PinkOutlineExt } from "../../../components/Button";
import { Link } from "react-router-dom";
import Web3Ctx from "../../../components/Context/Web3Ctx";
import {
  useGeneEcAllocatorContract,
  useGiveawayRegistryContract,
} from "../../../hooks/useContract";
import { useLocation } from "react-router";
import { displayGiveawayInfo } from "../util";
import { media } from "styled-bootstrap-grid";
import { ethers } from "ethers";
import config from "../../../config";

const CloseButton = styled.div`
  position: fixed;
  top: 15px;
  right: 15px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: white;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  & img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    filter: brightness(0) saturate(100) invert(23%) sepia(93%) saturate(5853%)
      hue-rotate(286deg) brightness(98%) contrast(93%);
  }
`;

const SlidePopup = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  transform-origin: 100% 16%;
  z-index: 11;
  overflow: hidden;
  transform: ${({ showPopup }) => (showPopup ? "scale(1)" : "scale(0)")};
  opacity: ${({ showPopup }) => (showPopup ? 1 : 0)};
  transition: all 0.4s ease-in-out;
  text-align: center;
`;

const SlideContent = styled.div`
  position: absolute;
  width: 92%;
  left: 4%;
  right: 4%;
  top: 10%;
  bottom: 4%;
  font-size: 1rem;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
  overflow: auto;
`;

const StyledDustAmountButton = styled(DustAmountEmbedButton)`
  position: absolute;
  top: 3%;
  left: 0;
  right: 0;
  z-index: 11;
  opacity: ${({ showButton }) => (showButton ? 1 : 0)};
  transition: all 0.4s ease-in-out;
`;

const Icon = styled.img`
  height: 50px;
  margin-bottom: 10px;
`;

const Title = styled.h4`
  // font-family: Teko;
  margin-top: 10px;
  margin-bottom: 30px;
  ${media.xs`
    margin-bottom: 10px;
  `}
`;

const Description = styled.p`
  color: #faf5fa;
  opacity: 0.64;
  font-size: 0.8em;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 30px;
`;

const Text = styled.div`
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  color: #grey;
`;

const Amount = styled.span`
  color: white;
  font-weight: 400;
  font-size: 1rem;
`;

const GiftNameWrapper = styled.div`
  max-height: 40%;
  overflow: auto;
  margin-top: 10%;
  margin-bottom: 10%;
`;

const GiftCardEmbedPopup = ({
  userIsOwner,
  tokenId,
  setVisible,
  visible,
  setButtonVisible,
}) => {
  const { ethersProvider, chainId } = useContext(Web3Ctx);
  const [hasGift, setHasGift] = useState(false);
  const [claimLocked, setClaimLocked] = useState(false);
  const [isEmbed, setIsEmbed] = useState(false);
  const [giftNames, setGiftNames] = useState([]);
  const [geneAmount, setGeneAmount] = useState(0.0);
  let location = useLocation();

  const giftContract = useGiveawayRegistryContract();
  const geneContract = useGeneEcAllocatorContract();

  useEffect(() => {
    setIsEmbed(location.pathname && location.pathname.includes("embed/"));
  }, [location]);

  useEffect(() => {
    if (tokenId) {
      checkCanClaim(tokenId);
    }
  }, [tokenId]);

  const checkCanClaim = async (tokenId) => {
    if (Number(tokenId) < 10) return;
    if (giftContract) {
      const result = await giftContract.getGiveawayIdsWhereTokenIsWinner(
        [tokenId],
        0,
        10
      );

      const hasGift = result[0].hasPrizes;
      if (hasGift) {
        const names = result[0].giveawayNames
          .filter((name) => {
            const parsedName = ethers.utils.parseBytes32String(name);
            return parsedName && parsedName !== "";
          })
          .map((name) => {
            return ethers.utils.parseBytes32String(name);
          });

        setGiftNames(names);
      }

      setHasGift(hasGift);
      setButtonVisible(hasGift);
    }

    console.log("gene contract", geneContract);

    // if (geneContract) {
    //   const res = await geneContract.getAvailableBalance([tokenId]);

    //   // console.log("res, gene", res, parseFloat(ethers.utils.formatEther(res)));
    //   if (res && parseFloat(ethers.utils.formatEther(res)) > 0) {
    //     setButtonVisible(true);
    //     setGeneAmount(parseFloat(ethers.utils.formatEther(res)));
    //   }
    // }
  };

  const showClaimButton = hasGift && userIsOwner && !claimLocked;
  const showClaimButtonForGene = false;
  //const showClaimButton = true;

  return (
    <>
      <SlidePopup showPopup={visible} className="px-2">
        <CloseButton
          onClick={(e) => {
            setVisible(false);
          }}
        >
          <img src={closeX} alt="close" />
        </CloseButton>

        <SlideContent>
          <Icon src={giftIcon}></Icon>
          <Title>Gifts</Title>
          <GiftNameWrapper>
            {giftNames.map((name) => {
              return (
                <Text key={name}>
                  <span>{name}:</span> <Amount>{1} </Amount>
                  {showClaimButton && (
                    <PinkOutline
                      style={{
                        padding: "0.1rem 0.2rem",
                        borderRadius: "0.2rem",
                        fontSize: "0.7rem",
                        display: "inline",
                      }}
                      as={Link}
                      to={{
                        pathname: `/wallet/gift`,
                        tokenId: tokenId,
                        giftAmount: 1,
                      }}
                    >
                      Claim
                    </PinkOutline>
                  )}
                </Text>
              );
            })}
          </GiftNameWrapper>

          <GiftNameWrapper>
            <Text>
              <span>Gene tokens:</span> <Amount>{geneAmount} </Amount>
              {showClaimButtonForGene && (
                <PinkOutlineExt href="https://claim.genomes.io" target="_blank">
                  Claim
                </PinkOutlineExt>
              )}
            </Text>
          </GiftNameWrapper>
        </SlideContent>
      </SlidePopup>
    </>
  );
};

export default GiftCardEmbedPopup;
