import React, { useEffect, useState } from "react";
import { LazyImage } from "../common/LazyImage";
import artistAvatar from "../../assets/images/nft-icons/artist-default-avatar.png";
import opensea from "../../assets/images/nft-icons/icon_opensea.png";
import etherscan from "../../assets/images/nft-icons/icon_etherscan.png";
import { getTokenMeta, getTokenURI } from "../Utils";
import RemoteImage from "../common/RemoteImage";

const WalletPunkCard = (props) => {
  const [metaUrl, setMetaUrl] = useState(null);
  const [tokenMeta, setTokenMeta] = useState(null);
  useEffect(() => {
    if (props.metaUrl && props.metaUrl !== metaUrl) {
      if(props.metaUrl.indexOf('/null')>-1){
        return;
      }

      setMetaUrl(props.metaUrl);
      getMeta(props.metaUrl);
    }
  }, [props]);

  const handleClick = () => {
    if (props.handleClick) {
      props.handleClick(props.index);
    }
  };

  const getMeta = async (url) => {
    let meta = await getTokenURI(url,0);
    setTokenMeta(meta);
    // console.log(url,meta);
  };

  return (
    <>
      {tokenMeta && (
        <div className="nft-card" onClick={handleClick}>
          <RemoteImage src={tokenMeta.image} />
          
        </div>
      )}
    </>
  );
};

export default WalletPunkCard;
