import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid';

export const BaseButton = styled.button`
    display: inline-block;
    font-weight: 500;
    text-align: center;
    min-width: 100px;
    text-transform: uppercase;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 3px solid transparent;
    padding: 0.8rem 1rem;
    font-size: 0.8rem;
    line-height: 1.5;
    border-radius: 0.5rem;
    transition: all .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    opacity: ${({ disabled }) => disabled ? 0.5 : 1};
    pointer-events: ${({ disabled }) => disabled ? "none" : "auto"};

    &:not(:disabled):not(.disabled) {
    cursor: pointer;
    }

    &:hover {
    text-decoration: none;
    color: inherit;
    }

    &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(189 92 153 / 25 %);
    }

    &:disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
    }
`
export const ButtonPink = styled(BaseButton)`
    background: #F51CDD;
    color: white;
    
    &:hover {
    opacity: 0.9;
    color: white;
}
`

export const ButtonOutline = styled(BaseButton)`
    background: none;
    border: solid 3px;

    &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(189 92 153 / 25 %);
}
`

export const PinkOutline = styled(ButtonOutline)`
    border-color: #F51CDD;
    border: solid 1px #F51CDD;
    color: white;
    white-space: pre-wrap;
    
    &:hover {
    background: #F51CDD;
    color: white;
    }

    &:disabled {
    }
`

export const PinkOutlineExt = styled.a`
    border-color: #F51CDD;
    border: solid 1px #F51CDD;
    color: white !important;
    cursor:pointer;
    text-transform:uppercase;
    white-space: pre-wrap;
    padding: 0.1rem 0.2rem;
    border-radius:0.2rem;
    font-size:0.7rem;
    display:inline;
    &:hover {
    background: #F51CDD;
    color: white;
    text-decoration:none;
    }

    &:disabled {
    }
`


export const ButtonText = styled(BaseButton)`
  color: #F51CDD;
  font-size: 0.9rem;
  padding: 0;
  width: fit-content;
  border: none;
  padding: 0;
  background: none;
  text-decoration: none;
  text-transform: none;
  &:focus {
    text-decoration: none;
  }
  &:hover {
    opacity: 0.9;
    color: #F51CDD;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonIcon = styled(ButtonPink)`
  height: auto;
  font-family: Poppins;
  font-weight: 600;
  padding: 0em 0em 0em 0em;
  font-size: 1em;
  border-radius: 0.6rem;
  background-color: #000;
  min-width: unset;
  opacity: 1;
  text-align: center;
  min-width: 2.4em;
  max-width: 2.4em;
  transition: opacity 0.4s ease-in-out, background-color 0.4s ease-in-out;
  z-index: 11;

  &:hover {
    background-color: #f51cdd;
  }
`;

const Icon = styled.img`
  max-height: 100%;
  max-width: 100%;
  padding: 0.2em 0.1em 0.2em 0.1em;
`;

export const EmbedButtonIcon = ({ className, iconSrc, onClick }) => {
  return (
    <ButtonIcon className={className} onClick={onClick}>
      <Icon src={iconSrc} />
    </ButtonIcon>
  );
};