import { useMemo } from 'react'
import useWeb3Ctx from "./useWeb3Ctx";
import config from "../config";
import { getContract } from '../utils'

import { SupportedChainId } from "../abi/constants/chain"

import { abi as ZOOM_ABI } from '../abi/Zoom2.json'
import { abi as EC_ABI } from '../abi/EtherCards.json'

import { abi as DUST_DISPENSER_ABI } from '../abi/DustDispenser.json'
import { abi as DUST_POOL_EXTRA_ALLOCATOR_ABI } from "../abi/DustPoolExtraAllocator.json"
import { abi as DUST_DISPENSER_V2_ABI } from '../abi/DustDispenserV2.json'
import { abi as DUST_TOKEN_ABI } from "../abi/DustToken.json"

import { abi as DUMMY721_ABI } from '../abi/Dummy721.json'
import { abi as GENE_EC_ALLOCATOR_ABI } from "../abi/GENEForEtherCardsAllocator.json"
import { abi as USER_TOKEN_ID_REGISTRY_ABI } from "../abi/UserTokenIdRegistry.json"
import { abi as DIRTY_ROBOT_TOKEN_ABI } from "../abi/DirtyRobotToken.json"
import { abi as GIVEAWAY_REGISTRY_ABI } from "../abi/GiveawayRegistry.json"

import {
    ZOOM_ADDRESSES,
    EC_ADDRESSES,

    DUST_DISPENSER_ADDRESSES,
    DUST_DISPENSER_V2_ADDRESSES,
    DUST_POOL_EXTRA_ALLOCATOR_ADDRESSES,
    DUST_TOKEN_ADDRESSES,

    MEEBITS_ADDRESSES,
    PUNKS_ADDRESSES,
    TYSONS_ADDRESSES,
    GIVEAWAY_REGISTRY_ADDRESSES,
    DIRTY_ROBOT_TOKEN_ADDRESSES,
    GENE_EC_ALLOCATOR_ADDRESSES,
    USER_TOKEN_ID_REGISTRY_ADDRESSES,
} from '../abi/constants/addresses'

// returns null on errors
export function useContract(
    addressOrAddressMap,
    ABI,
    withSignerIfPossible = true,
    contractChainId) {
    const {
        defaultProvider: deployedLibrary,
        ethersProvider: library,
        address: account,
        chainId,
        isCorrectNetwork,
        defaultChainId,
        getProvider } = useWeb3Ctx();

    return useMemo(() => {
        if (!isCorrectNetwork && (!deployedLibrary || !defaultChainId)) return null
        if (isCorrectNetwork && (!addressOrAddressMap || !ABI || !library || !chainId)) return null
        let address
        if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
        // Check if contractChainId is present then supply the appropriate provider for that contract
        // else check if currentNetwork is different from app deployed network
        else address = addressOrAddressMap[contractChainId ? contractChainId : isCorrectNetwork ? chainId : defaultChainId]
        if (!address) return null
        let provider = getProvider(contractChainId)
        // if (contractChainId) provider = getProvider(contractChainId)
        // else if (isCorrectNetwork) provider = library
        // else provider = deployedLibrary
        try {
            return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account, deployedLibrary, isCorrectNetwork])
}

export function useZoomContract() {
    return useContract(ZOOM_ADDRESSES, ZOOM_ABI, false)
}
export function useEcContract() {
    return useContract(EC_ADDRESSES, EC_ABI, false)
}

export function useDustDispenserContract() {
    return useContract(DUST_DISPENSER_ADDRESSES, DUST_DISPENSER_ABI, true)
}
export function useDustExtraAllocatorContract() {
    return useContract(DUST_POOL_EXTRA_ALLOCATOR_ADDRESSES, DUST_POOL_EXTRA_ALLOCATOR_ABI, true)
}
export function useDustDispenserV2Contract() {
    let contractChainId = null
    if (config.DEPLOYED_CHAIN_ID == SupportedChainId.MAINNET) {
        contractChainId = SupportedChainId.POLYGON
    }
    return useContract(DUST_DISPENSER_V2_ADDRESSES, DUST_DISPENSER_V2_ABI, true, contractChainId)
}
export function useDustTokenContract() {
    return useContract(DUST_TOKEN_ADDRESSES, DUST_TOKEN_ABI, false)
}
export function useDustTokenPolygonContract() {
    return useContract(DUST_TOKEN_ADDRESSES, DUST_TOKEN_ABI, false, SupportedChainId.POLYGON)
}


export function useMeebitsContract() {
    return useContract(MEEBITS_ADDRESSES, DUMMY721_ABI, false)
}
export function usePunksContract() {
    return useContract(PUNKS_ADDRESSES, DUMMY721_ABI, false)
}
export function useTysonsContract() {
    return useContract(TYSONS_ADDRESSES, DUMMY721_ABI, false)
}
export function useDirtyRobotTokenContract() {
    return useContract(DIRTY_ROBOT_TOKEN_ADDRESSES, DIRTY_ROBOT_TOKEN_ABI, false)
}

export function useGeneEcAllocatorContract() {
    return useContract(GENE_EC_ALLOCATOR_ADDRESSES, GENE_EC_ALLOCATOR_ABI, false, SupportedChainId.POLYGON)
}
export function useUserTokenIdRegistryContract() {
    return useContract(USER_TOKEN_ID_REGISTRY_ADDRESSES, USER_TOKEN_ID_REGISTRY_ABI, true)
}
export function useGiveawayRegistryContract() {
    return useContract(GIVEAWAY_REGISTRY_ADDRESSES, GIVEAWAY_REGISTRY_ABI, true)
}