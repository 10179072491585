import React, { useContext, useEffect, useState,useRef } from 'react';

import './RubberSpider.css';
import useContainerDimensions from '../common/useContainerDimensions';
import EmbeddedCard from '../common/EmbeddedCard';
import config from '../../config'

const RubberSpider = (props) => {

    const containerRef = useRef()
    const { width, height } = useContainerDimensions(containerRef);

    const [baseCid,setBaseCid] = useState('');
    const [tokenId,setTokenId] = useState(null);

    const series = {
        founder: {
            contractAddress: '0x000',
            baseCid: config.BASE_CID
        }
    }

    useEffect(()=>{

    },[width,height]);

    useEffect(()=>{
       // console.log('PROPS',props.match.params);
        let serie = props.match.params.series?props.match.params.series:'';
        let id = props.match.params.tokenId?parseInt(props.match.params.tokenId):0;

        if(id>9999){
           // setIllegalId(true);
            return;
        }else{
          //  setIllegalId(false);
          setTokenId(id);
        }

        if(series[serie] ){
            //console.log('series found:',serie, id);
            setBaseCid(series[serie].baseCid);

          //  getMeta(series[serie].baseCid, id);
        }else{
  
        }

    },[props]);


    return(
        <div ref={containerRef} className="e-container">
            <div className="positioner mx-auto p-4" style={{width: width<height/1.365?100+'%':height/1.365,
                                                            marginTop: width<height/1.365?(height-(width*1.365))/2:0}}>
                {tokenId!=null && <EmbeddedCard demoMode="true" 
                    tokenJsonUri = {baseCid+'/'+tokenId%100+'/'+tokenId+'.json'}
                    tokenImage={null}
                    tokenId={tokenId}
                    cardType={tokenId<10?4:tokenId<100?0:tokenId<1000?1:3}
                />}
            </div>

        </div>
    );
}

export default RubberSpider;