import React, { useState, useEffect } from "react";
import {
  useDustDispenserContract,
  useDustDispenserV2Contract,
  useDustExtraAllocatorContract,
  useDustTokenContract,
  useDustTokenPolygonContract
} from '../../../hooks/useContract';
import useWeb3Ctx from "../../../hooks/useWeb3Ctx";
import { PinkOutline, ButtonText } from "../../../components/Button";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import { Row } from "../../../components/Row";
import { ColAlign } from "../../../components/Col";
import { formatDustAmount } from "../../../components/Utils";
import { media } from "styled-bootstrap-grid";
import dustIcon from "../../../assets/images/dust-icon.png";

const Title = styled.p`
  font-weight: 700 !important;
  font-size: 1.4rem;
  color: white !important;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
`;

const Wrapper = styled.div`
  text-align: center;
  margin-top: 1.5rem;
`;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.2rem;
  color: grey;
  background: #0b122954;
  border-radius: 10px;
  padding: 15px 20px;
  text-align: left;
  margin: ${({ margin }) => margin ? margin : "5px 0"};
`;

const Text = styled.p`
  margin-top: 10px;
  color: grey;
  font-weight: 500;
  line-height: 2rem;
`;
const TextSmall = styled(Text)`
  font-size: 0.9rem;
  margin-top: 30px;
`;

const DustText = styled.div`
  align-self: center;
`;
const AmountText = styled.span`
  color: white;
  font-weight: 500;
  margin-right: 10px;
`;
const DustTextSmall = styled.div`
  align-self: center;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  ${media.xs`
    margin-right: 0;
  `}
  ${media.sm`
    margin-right: 0;
  `}
  ${media.md`
    margin-right: 20px;
  `}
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${media.xs`
    flex-direction: column-reverse;
    margin-left: 20px;
  `}
`;

const LineBreakResponsive = styled.br`
  display: none;
  ${media.xs`
  display: inline
`}
`;

const StyledPinkOutline = styled(PinkOutline)`
    display: inline;
    align-self: center;
  ${media.xs`
    max-width: 110px;
    min-width: 110px;
    white-space: normal;
  `}
  ${media.sm`
    max-width: 110px;
    min-width: 110px;
    white-space: normal;
  `}
  ${media.md`
    max-width: unset;
  `}
`;

const DustIcon = styled.img`
  height: 28px;
  width: 28px;
  padding: 6px 8px;
  border-radius: 4px;
  background: #f51bdc;
`;

const NetworkName = styled.span`
  color: grey
`

const DustWithdrawal = ({ tokenIds, showTnc }) => {
  const { address } = useWeb3Ctx();
  const [dustAmount, setDustAmount] = useState("0");
  const [dustWalletAmount, setDustWalletAmount] = useState("0");

  const [dustAmountPolygon, setDustAmountPolygon] = useState("0");
  const [dustWalletAmountPolygon, setDustWalletAmountPolygon] = useState("0");

  const [claimLocked, setClaimLocked] = useState(false);

  const dustContract = useDustDispenserContract();
  const dustExtraContract = useDustExtraAllocatorContract();
  const dustExtra2Contract = useDustDispenserV2Contract();
  const dustTokenContract = useDustTokenContract();
  const dustTokenPolygonContract = useDustTokenPolygonContract();

  useEffect(() => {
    if (dustExtraContract && dustExtra2Contract) {
      // checkContractLocked();
      if (tokenIds && tokenIds.length > 0) {
        fetchAllDustAmount(tokenIds);
      }
    }
  }, [dustExtraContract, dustExtra2Contract, tokenIds]);

  useEffect(() => {
    if (dustTokenContract && dustTokenPolygonContract && address) {
      fetchWalletDustAmount();
    }
  }, [dustTokenContract, dustTokenPolygonContract, address]);

  // Not needed anymore
  // const checkContractLocked = async () => {
  //   const locked = await dustContract["locked"]();
  //   console.log('contract locked:', locked)
  //   if (locked) {
  //     setClaimLocked(true);
  //   } else {
  //     const unlockTime = await dustContract["unlockTime"]();
  //     const currentTime = (Date.now() / 1000 | 0);
  //     console.log('unlock time:', Number(unlockTime))
  //     setClaimLocked(Number(unlockTime) > currentTime)
  //   }
  // };

  const fetchAllDustAmount = async (tokenIds) => {
    const filteredIds = tokenIds.filter((tokenId) => Number(tokenId) >= 10);
    const totalDustAmount = await dustContract["getAvailableBalance(uint16[])"](
      filteredIds
    );
    const totalDustExtraAmount = await dustExtraContract["getAvailableBalance(uint16[])"](filteredIds)

    const totalDustExtra2Amount = await dustExtra2Contract["getAvailableBalance(uint16[])"](filteredIds)

    const sumOfDust = totalDustAmount.add(totalDustExtraAmount);

    if (sumOfDust > 0) {
      setDustAmount(formatDustAmount(sumOfDust));
    }

    if (totalDustExtra2Amount > 0) {
      setDustAmountPolygon(formatDustAmount(totalDustExtra2Amount));
    }
  };

  const fetchWalletDustAmount = async () => {
    const dust = await dustTokenContract["balanceOf"](address);
    if (dust) {
      setDustWalletAmount(formatDustAmount(dust));
    }

    const dustPolygon = await dustTokenPolygonContract["balanceOf"](address);
    if (dustPolygon) {
      setDustWalletAmountPolygon(formatDustAmount(dustPolygon));
    }
  };

  return (
    <Wrapper>
      <Title>Dust</Title>
      <Row>
        <ColAlign col={12} md={10} lg={8} align="center">
          <Text>
            You can claim the Dust from all of your cards in one single
            transaction or choose an individual card you want to claim by
            selecting it and clicking on the <DustIcon src={dustIcon} /> Dust
            icon, then clicking Claim. Pools will be activated on Oct 8th.
          </Text>
          {!claimLocked && (
            <TextSmall>
              Note: The following{" "}
              <ButtonText onClick={() => showTnc()}>
                Term and Conditions
              </ButtonText>{" "}
              apply for claiming Dust.
            </TextSmall>
          )}
        </ColAlign>

        <ColAlign col={12} md={12} lg={9} align="center">
          <AmountContainer>
            <DustText>
              {"Total Dust on your cards"}: &nbsp;
              <br />
              <AmountText><NetworkName>Mainnet:</NetworkName> {dustAmount || "0"}</AmountText>
              <br />
              <AmountText><NetworkName>Polygon:</NetworkName> {dustAmountPolygon || "0"}</AmountText>
            </DustText>

            <ButtonContainer>
              {claimLocked && <DustTextSmall>Coming Soon</DustTextSmall>}
              <StyledPinkOutline
                disabled={claimLocked || (Number(dustAmount) == 0 && Number(dustAmountPolygon == 0))}
                // as={Link}
                // to={{
                //   pathname: `/wallet/dust`,
                //   tokenIds: tokenIds,
                //   dustAmount: dustAmount,
                // }}
                onClick={() => {
                  showTnc({
                    pathname: `/wallet/dust`,
                    tokenIds: tokenIds,
                    dustAmount: dustAmount,
                  });
                }}
              >
                Claim All Dust
              </StyledPinkOutline>
            </ButtonContainer>
          </AmountContainer>
        </ColAlign>

        <ColAlign col={12} md={12} lg={9} align="center">
          <AmountContainer>
            <DustText>
              {"Total Dust in your wallet"}: &nbsp;
              <br />
              <AmountText><NetworkName>Mainnet:</NetworkName> {dustWalletAmount || "0"}</AmountText>
              <br />
              <AmountText><NetworkName>Polygon:</NetworkName> {dustWalletAmountPolygon || "0"}</AmountText>
            </DustText>

            <ButtonContainer>
              {/* <DustTextSmall>Coming Soon</DustTextSmall> */}
              <StyledPinkOutline
                as={"a"}
                href="https://dust-pools.ether.cards"
                target="_blank"
              >
                See Pools
              </StyledPinkOutline>
            </ButtonContainer>
          </AmountContainer>
        </ColAlign>
      </Row>
    </Wrapper>
  );
};

export default DustWithdrawal;
