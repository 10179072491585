
import { Col as ColBootstrap } from "styled-bootstrap-grid";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";

export const Col = ColBootstrap;

export const ColAlign = styled(Col)`
    text-align: ${({ align }) => align && align};
    margin: auto;

    ${media.xs`
    text-align: ${({ xsAlign }) => xsAlign && xsAlign};
    `}
    ${media.sm`
        text-align: ${({ smAlign }) => smAlign && smAlign};
    `}
    ${media.md`
        text-align: ${({ mdAlign }) => mdAlign && mdAlign};
    `}
`;
