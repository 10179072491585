import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './Home.css';
import combined from '../../assets/metas.json';
import artists from '../../assets/artists.json';
import { LazyImage } from '../common/LazyImage';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toast';
import Web3Ctx from '../Context/Web3Ctx';


import artistAvatar from '../../assets/images/nft-icons/artist-default-avatar.png';
import opensea from '../../assets/images/nft-icons/icon_opensea.png';
import etherscan from '../../assets/images/nft-icons/icon_etherscan.png';
import mag from '../../assets/images/nft-icons/icon_search.png';
import Explorer from './Explorer';
import config from '../../config'
import { ECNav } from 'ec-commons';
import useUnload from '../Hooks/useUnload';

import axios from "axios";
import DustTnc from "../../pages/Dust/components/DustTnc";
import RestrictedCountries from "../../pages/Dust/components/RestrictedCountries"
import blockedCountries from "../../constants/blockedCountries";

const ITEMS_PER_PAGE = 20;

const CARD = {
    CREATOR: 'creator',
    OG: 'og',
    ALPHA: 'alpha',
    FOUNDER: 'founder'
}




const Home = (props) => {
    const {onboard,address} = useContext(Web3Ctx);
    const [currentPage,setCurrentPage] = useState(0);
    const [allCards, setAllCards] = useState([]);
    const [theCards, setTheCards] = useState([]);
    const [cardIdInput,setCardIdInput] = useState('');

    const [cardType,setCardType] = useState('all');
    const [artistName, setArtistName] = useState('');
    const [onlyPerfect,setOnlyPerfect] = useState(false);

    const [showExplorer,setShowExplorer]=useState(false);
    
    const [tokenId, setTokenId] = useState(0);
    const [serie, setSerie] = useState('founder');

    const [selectedIdx, setselectedIdx] = useState(null);

    const [showTnc, setShowTnc] = useState(null);
    const [showRestricted, setShowRestricted] = useState(false);
    const [userCountryCode, setUserCountryCode] = useState(null);
    const [countryBlocked, setCountryBlocked] = useState(false);

    const baseServer = config.BASE_SERVER;


    const unload = useCallback(() => { 
        console.log('unload...')
        localStorage.removeItem('filters');
        
    }, []);

    useEffect(() => {
        if (userCountryCode) {
            const blocked = Object.keys(blockedCountries).includes(userCountryCode)
            setCountryBlocked(blocked)
        }
    }, [userCountryCode])

    useUnload(unload);

    useEffect(()=>{
            fetchUserCountry();

            let filters = getSavedFilters();
            //console.log('filters at mount',filters);

            if(filters.id_string!==''){
                setCardIdInput(filters.id_string);
                _showSelectedIds(filters.id_string);

            }else{
                setCardType(filters.card_type);
                setArtistName(filters.artist_name);
                setOnlyPerfect(filters.only_perfect);

                combinedFilter(filters.card_type,filters.artist_name,filters.only_perfect);
            }
/* 
            let cards=[];
            
            for(let k=0;k<10000;k++){
                cards.push({
                    id: combined[k].id,
                    imgUrl: combined[k].imageURL
                });
            }
            console.log('start rendering',cards.length);
            setAllCards(cards); */

            return (()=>{
               // console.log("CLEANUP");
                localStorage.removeItem('filters');
            });



    },[]);

    useEffect(()=>{
        //console.log('about to load the first batch');

        if(allCards.length>0){
            

            loadNext();
        }
    },[allCards]);

    useEffect(()=>{

        let serie = props.match.params.series ? props.match.params.series : '';
        if (serie === 'founder') {
            let id = props.match.params.tokenId ? Number(props.match.params.tokenId) ?
                parseInt(props.match.params.tokenId) : props.match.params.tokenId : 0;
            if (!isNaN(Number(id))) {
                if (id > 9999) {
                    props.history.push('/');
                    return;
                }
                setTokenId(id);
                setShowExplorer(true);
            } else {
                if (Object.values(CARD).filter(type => type === id).length > 0) {
                    handleCardTypeSelect(id)
                } else {
                    props.history.push('/');
                    return;
                }
            }
            setSerie('founder');
        }

    },[props]);

    useEffect(()=>{
        if(tokenId !== null) {
            console.log('der token id',tokenId);
            let idx = getCurrentIdxFromSubset(tokenId);
            

            if(idx!==null){
                setselectedIdx(idx);
            }else{

                if(Number(tokenId)<Number(allCards[0].id)) {
                    setselectedIdx(Number(tokenId)-Number(allCards[0].id));
                }

                if(Number(tokenId)>Number(allCards[allCards.length-1].id)) {
                    setselectedIdx(allCards.length-1+(Number(tokenId)-Number(allCards[allCards.length-1].id)));
                }


            }
        }
        
    },[tokenId])



     const getCurrentIdxFromSubset = (id) =>{
        if(allCards.length>0){
            for(let i = 0; i < allCards.length; i++){
                //Strange... === don't work here. idk why, both values ARE numbers :/

                if(allCards[i].id == id){
                    return i;
                }
            }

   /*          //subset doesn't contains the id, reset subset.

            setCardIdInput('');
            setCardType('all');
            setArtistName('');
            setOnlyPerfect(false);

            let f = {
                card_type: 'all',
                artist_name: '',
                only_perfect: false,
                id_string: ''
            }
           if(localStorage){
            localStorage.setItem('filters',JSON.stringify(f));
           }
           combinedFilter('all','',false);

 */

           return null;
        }



    } 

    const loadNext = ()=>{
        let c=[];
        let end = allCards.length<ITEMS_PER_PAGE?allCards.length:ITEMS_PER_PAGE;
        for(let i=0;i<end;i++){
            c.push(allCards[currentPage*ITEMS_PER_PAGE+i]);
        }
        setTheCards(theCards => theCards.concat(c));
        setCurrentPage(currentPage+1);
   }


   const handleClick = (id,idx) => {
        /* window.scrollTo(0,0);*/
     setselectedIdx(idx);
     props.history.push('/founder/'+id);
   }

   const getSavedFilters = ()=>{
        let f = {
            card_type: 'all',
            artist_name: '',
            only_perfect: false,
            id_string: ''
        }
       if(localStorage){
            let localFilters = localStorage.getItem('filters');
            if(localFilters){
                f=JSON.parse(localFilters);
            }
       }
       return f;
   }

   const setSavedFilters = (value) => {
       let f = getSavedFilters();

       let newFilters = {...f, ...value};

       //console.log('New filter', value, newFilters);

       if(localStorage){
        localStorage.setItem('filters',JSON.stringify(newFilters));
       }
   }

   
   //filters 
    const handleCardTypeSelect = (value) => {
        setCardType(value);
        setCardIdInput('');
        setSavedFilters({card_type:value,id_string:''});
        combinedFilter(value,artistName,onlyPerfect);
        return;
    }

    const handleArtistSelect = (value) => {

//        console.log(value)
        setArtistName(value);
        setCardIdInput('');
        setSavedFilters({artist_name:value,id_string:''});

        combinedFilter(cardType,value,onlyPerfect);
        return;
    }

    const handlePerfectChecked = (value) => {
        setOnlyPerfect(value);
        setCardIdInput('');
        setSavedFilters({only_perfect:value,id_string:''});
        combinedFilter(cardType,artistName,value);
    }


    const combinedFilter = (cardType,artist,perfect)=>{
        let s = 0;
        let e= 10000;

        switch (cardType){
            case CARD.CREATOR:
                {
                    s=0;
                    e=10;
                    break;
                }
            case CARD.OG:
                {
                    s=10;
                    e=100;
                    break;
                }
            case CARD.ALPHA:
                {
                    s=100;
                    e=1000;
                    break;
                }
            case CARD.FOUNDER:
                {
                    s=1000;
                    e=10000;
                    break;
                }
            default:
            {
                break;
            }
        }

        let cards=[];
        let layer=[];
        for(let i=s;i<e;i++){

            let res=i;

            let layeredOnly = false;

            if(artist){
                if(combined[i].artist!==artist && combined[i].layerArtist.includes(artist)===false){
                    res=null;
                }else{
                    if(combined[i].artist!==artist){
                        layeredOnly = true;
                    }
                }
            }

            if(res!==null && perfect===true){
                if(combined[i].perfect===false){
                    res=null;
                }
            }

            if(res!==null){

                if(!layeredOnly){
                    cards.push({
                        id: combined[res].id,
                        imgUrl: combined[i].imageURL
                    });
                }else {
                    layer.push({
                        id: combined[i].id,
                        imgUrl: combined[i].imageURL
                    });
                }
            }

        }
        
        setCurrentPage(0);
        setTheCards([]);


        if(layer.length>0){
            setAllCards(cards.concat(layer));
        }else{
            setAllCards(cards);
        }

        window.scrollTo(0,0);
    }


    const showSelectedIds = ()=>{

        if(cardIdInput === ''){
            toast.error('Enter some card IDs. Comma separated list or a range (23-42)');
            combinedFilter(cardType,artistName,onlyPerfect);
            return;
        }

        setSavedFilters({id_string:cardIdInput}); 
        _showSelectedIds(cardIdInput);
    }




    const _showSelectedIds = (queryString)=>{

        let rawInput = queryString.replace(/ /g,',');

        let idArray = rawInput.split(',');
        
        let cards=[];
        for(let i=0;i<idArray.length;i++){
            let item=idArray[i];
            if(item){
                
                if(item.indexOf('-')>0){
                    let range = item.split('-');
                    if(range[range.length-1]===''){
                        toast.warn('Missing \'to\' param.');
                    }else{

                        let s=parseInt(range[0]);
                        let e=parseInt(range[range.length-1]);

                        if(!isNaN(s) && !isNaN(e)){
                            if(e<s){
                                let t = s;
                                s=e;
                                e=t;
                            }

                            if(s===e){
                                toast.warn('Range '+s+' to '+e+' selected. :)'); 
                            }

                            if(e>9999 || s<0){
                                toast.warn('Out of range.'); 
                            }else{
                                for(let j=s;j<=e;j++){
                                    cards.push({
                                        id: combined[j].id,
                                        imgUrl: combined[j].imageURL
                                    });
                                }
                            }





                        }else{
                            toast.warn('Wrong param format. Range should be like \'23-42\'');
                        }
                    }

                }else{
                    if(!isNaN(parseInt(item))){
                        if(parseInt(item)>=0){
                            if(parseInt(item)>9999){
                                toast.warn('Out of range.'); 
                            }else{
                                cards.push({
                                    id: combined[parseInt(item)].id,
                                    imgUrl: combined[parseInt(item)].imageURL
                                });
                            }
                        }else{
                            toast.warn('The card ID must be positive: '+item);
                        }
                    }else{
                        toast.warn('Invalid value: '+item);
                    }
                }
            }
        }

        //console.log('filtered',cards.length);
        setCurrentPage(0);
        setTheCards([]);
        setAllCards(cards);
        window.scrollTo(0,0);
    }
    
    const handlePrevNextClick =(v)=>{
        let newIdx = selectedIdx+v;
        setselectedIdx(newIdx);

        let cardId = null;
        if(newIdx >= 0 && newIdx<allCards.length){
            cardId = allCards[newIdx].id;
        }

        if(newIdx<0) {
            cardId = Number(allCards[0].id)+newIdx;
        }

        if(newIdx>allCards.length-1) {
            cardId = Number(allCards[allCards.length-1].id)+(newIdx-(allCards.length-1));
        }
        cardId = cardId<0?0:cardId>9999?9999:cardId;
        props.history.push('/founder/' +  cardId /* allCards[newIdx].id */);
    }

    const showTncAndClaim = (claimData) => {
        if (claimData && countryBlocked) {
            setShowRestricted(true)
        } else {
            setShowTnc(claimData || true);
        }
    }

    const fetchUserCountry = async () => {
        const res = await axios.get(`${baseServer}/api/location`).catch(e => console.log(e));
        if (res && res.data && res.data.country_code) {
            const countryCode = res.data.country_code;
            setUserCountryCode(countryCode);
        } else {
            toast.error('Unable to determine your country')
        }
    }
    
    return (
        <>
            <ECNav projectUrl={config.APP_BASE_URL} onboard={onboard} address={address} />
            
            <div className="container mt-5 home">
                <div className="filters w-100 mt-0  pt-3 pb-0 ">
                    <div className="content col-md-7 mx-auto ">
                        <div className="row px-2 py-2">
                            <div className="filter-item">
                                <h5>Card Type</h5>
                                <div className="selected-option open">
                                    {cardType==='og'?'OG':cardType==='all'?'All card types':cardType}
                                    <div className="dropdown">
                                        <div className="dd-container">

                                            <ul className="">
                                                <li onClick={()=>handleCardTypeSelect("all")}>All</li>
                                                <li onClick={()=>handleCardTypeSelect(CARD.CREATOR)}>Creator</li>
                                                <li onClick={()=>handleCardTypeSelect(CARD.OG)}>OG</li>
                                                <li onClick={()=>handleCardTypeSelect(CARD.ALPHA)}>Alpha</li>
                                                <li onClick={()=>handleCardTypeSelect(CARD.FOUNDER)}>Founder</li>
                                            </ul>
                                        
                                        </div>
                                    </div>
                                
                                </div>


                            </div>

                            <div className="filter-item">
                                <h5>Artist</h5>
                                <div className="selected-option open">
                                    {artistName?artistName:'All artists'}
                                    <div className="dropdown">
                                        <div className="dd-container">
                                            <ul className="">
                                            <li onClick={()=>handleArtistSelect('')}>All</li>
                                                {artists.map((i,idx)=>{return(
                                                <li key={'a'+i} onClick={()=>handleArtistSelect(i)}>{i}</li>
                                                )})}
                                            </ul>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="filter-item">
                                <h5>Layers</h5>
                                <div className="selected-option open">
                                    {onlyPerfect?'Perfects only':'All'}
                                    <div className="dropdown">
                                        <div className="dd-container">
                                            <ul className="">
                                                <li onClick={()=>handlePerfectChecked(false)}>All</li>
                                                <li onClick={()=>handlePerfectChecked(true)}>Perfects only</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="filter-item">
                                <div className="id-filter d-flex">
                                    <input type="text" 
                                        className="" 
                                        placeholder="Card IDs"
                                        value = {cardIdInput}
                                        onChange={(e)=>setCardIdInput(e.target.value)}
                                        onKeyUp={(e)=>{if(e.key === "Enter"){showSelectedIds()}}}
                                        />
                                    <button className="ml-auto mr-1" onClick={showSelectedIds}><img src={mag}/></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className="back-top d-md-none d-block"
                    onClick={()=>{
                        if(window){
                            window.scrollTo({top:0,left:0,behavior: 'smooth'});
                        }
                     }
                    }>
                    &#8682;
                </div>

                {theCards.length>0 &&
                    <InfiniteScroll
                    dataLength={theCards.length}
                    next={loadNext}
                    hasMore={currentPage*ITEMS_PER_PAGE<allCards.length}
                    loader={<h4>Loading...</h4>}
                >
                    <div className="row mt-5 mx-0 pt-3 h-100">

                        {theCards.map((v,i)=> {return(
                                <div key={'card-'+i} className="col-md-3">
                                    {v&&<div className="nft-card" onClick={()=>handleClick(v.id,i)}>
                                        <LazyImage src={v.imgUrl}/>
                                        <div className="nft-type">
                                            {v.id<10?'CREATOR':v.id<100?'OG':v.id<1000?'ALPHA':'FOUNDER'}
                                        </div>

                                        <div className="info-panel row pl-4 py-2 mx-0">
                                            <div className="col-2 avatar">
                                                <img src={artistAvatar} alt="" />
                                            </div>
                                            <div className="col-10 pl-1 details">
                                                <h5>{combined[v.id].artist}</h5>
                                                <p className="mb-1">{combined[v.id].title}</p>
                                                <p className="mb-1"><span className="gray-text">ID:</span> {combined[v.id].id}</p>
                                            
                                            
                                                <div className="link-panel">
                                                <a href={`https://opensea.io/assets/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/${v.id}`} onClick={(e)=>{e.stopPropagation()}} target="_blank" className="lnk opensea">
                                                    <img src={opensea} alt="opensea link"/>
                                                </a>
                                                <a href={`https://etherscan.io/token/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8?a=${v.id}`} onClick={(e)=>{e.stopPropagation()}} target="_blank" className="lnk etherscan">
                                                    <img src={etherscan} alt="etherscan link"/>
                                                </a>
                                            </div>
                                            </div>

                                            
                                        </div>

                                    </div>}
                                </div>

                            );})
                        }

                    </div>
                </InfiniteScroll>}

                {theCards.length===0 && 
                    <div className="row mt-5 pt-3 h-100 text-center">
                        <h5 className="mt-5 w-100">No {onlyPerfect?'perfect ':''}{cardType&&cardType!=='all'?cardType.toUpperCase():''} cards found {artistName?'from '+artistName:''}.</h5>
                    </div>

                }

            </div>

            {showExplorer&&
                <Explorer 
                    visible={true}
                    tokenId={tokenId}
                    serie = {serie}
                    onClose = {()=>setShowExplorer(false)}
                    setSize = {allCards.length}
                    currentIdx = {selectedIdx}
                    onNavigateClick={handlePrevNextClick}
                    showTncAndClaim={showTncAndClaim}
                />}

            <DustTnc showTnc={showTnc} hideTnc={() => setShowTnc(null)} />
            <RestrictedCountries show={showRestricted} hide={() => setShowRestricted(false)}></RestrictedCountries>
        </>
    );

}

export default Home;