import React, { useContext, useEffect, useState } from 'react';
import './WalletPage.css';
import Web3Ctx from '../Context/Web3Ctx';
import { useEcContract, usePunksContract, useMeebitsContract, useTysonsContract, useZoomContract } from '../../hooks/useContract';
import { toast } from 'react-toast';
import { SpinnerDotted } from 'spinners-react';
import config from '../../config'
import WalletCard from './WalletCard';
import Explorer from '../Home/Explorer';
import { ECNav } from 'ec-commons';
// import CardHolder from "./CardHolder";
import { Zoom } from "zoom-next";
import WalletPunkCard from './WalletPunkCard';
import { PinkOutline } from '../Button';
import queryString from "query-string";

import styled from 'styled-components';
import DustWithdrawal from '../../pages/Dust/components/DustWithdrawal';
import GiftWithdrawal from '../../pages/Gift/components/GiftWithdrawal';
import DustTnc from '../../pages/Dust/components/DustTnc';
import RestrictedCountries from "../../pages/Dust/components/RestrictedCountries"
import axios from 'axios';
import blockedCountries from "../../constants/blockedCountries";
import WalletMeebitCard from './WalletMeebitCard';
// import GeneWithdrawal from '../../pages/Gene/GeneWithdrawal';

const getParameterByName = (name, url = window.location.href) => {
    const n = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${n}(=([^&#]*)|&|#|$)`),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const Title = styled.p`
    font-weight: 700 !important;
    font-size: 1.4rem;
    color: white !important;
`;


const WalletPage = (props) => {
    const { onboard, address, handleConnect } = useContext(Web3Ctx);
    const [isConnected, setIsConnected] = useState(false);

    const [cards, setCards] = useState([]);
    const [tokenIds, setTokenIds] = useState([]);
    const [thePunks, setThePunks] = useState([]);
    const [theMeebits, setTheMeebits] = useState([]);
    const [theTysons, setTheTysons] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [showExplorer, setShowExplorer] = useState(false);
    const [selectedIdx, setSelectedIdx] = useState(null);
    const [tokenId, setTokenId] = useState(null);
    const [showCardHolder, setShowCardHolder] = useState(false);
    const [showTnc, setShowTnc] = useState(null);
    const [showRestricted, setShowRestricted] = useState(false);
    const [userCountryCode, setUserCountryCode] = useState(null);
    const [countryBlocked, setCountryBlocked] = useState(false);

    const [showTokenId, setShowTokenId] = useState(null);
    const [giftIds, setGiftIds] = useState([])

    const baseCid = config.BASE_CID;
    const baseServer = config.BASE_SERVER;

    const ec = useEcContract();
    const punkContract = usePunksContract();
    const meebitContract = useMeebitsContract();
    const tysonContract = useTysonsContract();
    const zoom2 = useZoomContract();

    useEffect(() => {
        fetchUserCountry();
        const { tokenId } = props.location.search
            ? queryString.parse(props.location.search)
            : props.location;
        console.log(tokenId)
        if (tokenId) {
            setShowTokenId(tokenId)
        }
    }, []);

    useEffect(() => {
        if (userCountryCode) {
            const blocked = Object.keys(blockedCountries).includes(userCountryCode)
            setCountryBlocked(blocked)
        }
    }, [userCountryCode])

    useEffect(() => {
        const cardId = getParameterByName('cardId', window.location.href);
        if (cardId) {
            const index = cards.findIndex((card) => parseInt(card) === parseInt(cardId));
            if (index >= 0) {
                setSelectedIdx(index);
            }
        }
        setTokenIds(cards.map(card => Number(card.id)))
    }, [cards]);

    useEffect(() => {
        if (cards.length > 0 && selectedIdx !== null) {

            if (selectedIdx <= cards.length) {
                console.log('SHOW EXPLORER');
                setTokenId(cards[selectedIdx].id);
                setShowExplorer(true);

            } else {
                toast.error('Invalid index.');
            }

        }
    }, [cards, selectedIdx]);

    useEffect(() => {
        if (cards.length > 0 && showTokenId !== null) {
            if (cards.find(card => Number(card.id) === showTokenId)) {
                setTokenId(showTokenId);
                setShowExplorer(true);
            }
        }
    }, [cards, showTokenId])

    useEffect(() => {
        if (ec && punkContract && meebitContract && tysonContract && address && zoom2) {
            //console.log('about to get tokens [ec,address]',ec,address);
            setIsConnected(true);
            getTokens();
        } else {
            setShowExplorer(false);
            setIsConnected(false);
            setCards([]);
            setThePunks([]);
            setTheMeebits([]);
            setTheTysons([]);
        }
    }, [ec, punkContract, meebitContract, tysonContract, address, zoom2]);

    const getTokens = async () => {
        /* if (!ec ) {
            toast.error('Contract not found');
            return;
        } */
        setIsLoading(true);

        let ZoomLibraryInstance = new Zoom({ use_reference_calls: true });
        let calls = [];

        //nr of ec cards
        const numOfEC = ZoomLibraryInstance.addCall(
            ec,
            ["balanceOf", [address]],
            "balanceOf(address) returns (uint256)"
        );
        calls.push(numOfEC);

        //nr of punks
        const numOfPunkz = ZoomLibraryInstance.addCall(
            punkContract,
            ["balanceOf", [address]],
            "balanceOf(address) returns (uint256)"
        );
        calls.push(numOfPunkz);

        //nr of meebits
        const numOfMeebits = ZoomLibraryInstance.addCall(
            meebitContract,
            ["balanceOf", [address]],
            "balanceOf(address) returns (uint256)"
        );
        calls.push(numOfMeebits);

        //nr of tysonz
        const numOfTysons = ZoomLibraryInstance.addCall(
            tysonContract,
            ["balanceOf", [address]],
            "balanceOf(address) returns (uint256)"
        );
        calls.push(numOfTysons);

        const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();

        //console.log("======== ZOOM CALL START ============" );
        //console.time('zoomCall');
        const combinedResult = await zoom2.combine(ZoomQueryBinary);
        //console.timeEnd('zoomCall');
        //console.log("======== ZOOM CALL END ==============" );

        ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);

        const nt = Number(ZoomLibraryInstance.decodeCall(calls[0]));
        const np = Number(ZoomLibraryInstance.decodeCall(calls[1]));
        const nm = Number(ZoomLibraryInstance.decodeCall(calls[2]));
        const nty = Number(ZoomLibraryInstance.decodeCall(calls[3]));

        console.log('ec:', nt, 'punk:', np, 'meebit:', nm, 'tyson:', nty);

        ZoomLibraryInstance = new Zoom({ use_reference_calls: true });

        if (nt > 0) {

            let calls = [];
            for (let i = 0; i < nt; i++) {
                // request the token ID
                /*  const tId = ZoomLibraryInstance.addCall(
                   ec,
                   ["tokenOfOwnerByIndex", [address, i]],
                   "tokenOfOwnerByIndex(address,uint256) returns (uint256)"
                 );
                 calls.push(tId); */

                const tId = ZoomLibraryInstance.addMappingCountCall(
                    ec,
                    ["tokenOfOwnerByIndex", [address, i]],
                    "tokenOfOwnerByIndex(address,uint256) returns (uint256)",
                    [{ contract: ec, mapAndParams: ["tokenURI(uint256)", [i]] }]
                );
                calls.push(tId);

                const tUri = ZoomLibraryInstance.addType5Call(
                    ec,
                    ["tokenURI(uint256)", [i]],
                    "tokenURI(uint256) returns (string)"
                );
                calls.push(tUri);
            }




            const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();

            /* console.log("======== ZOOM CALL START ============");
            console.time("zoomCall_ec"); */
            const combinedResult = await zoom2.combine(ZoomQueryBinary);
            /* console.timeEnd("zoomCall_ec");
            console.log("======== ZOOM CALL END =============="); */

            ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);

            let ecTokens = [];
            let punkTokens = [];
            let meebitTokens = [];
            let tysonTokens = [];
            for (let i = 0; i < nt * 2; i = i + 2) {
                const id = ZoomLibraryInstance.decodeCall(calls[i]).toString();
                // console.log('TOKEN ID', id);
                const tokenURI = ZoomLibraryInstance.decodeCall(calls[i + 1]).toString();
                ecTokens.push({ id, tokenURI });
            }

            if (np > 0) {
                ZoomLibraryInstance = new Zoom({ use_reference_calls: true });
                let calls = [];
                for (let i = 0; i < np; i++) {
                    // request the token ID
                    const tId = ZoomLibraryInstance.addMappingCountCall(
                        // the contract we're calling
                        punkContract,
                        ["tokenOfOwnerByIndex", [address, i]],
                        "tokenOfOwnerByIndex(address,uint256) returns (uint256)",
                        [{ contract: punkContract, mapAndParams: ["tokenURI(uint256)", [i]] }]
                    );
                    calls.push(tId);

                    const tUri = ZoomLibraryInstance.addType5Call(
                        punkContract,
                        ["tokenURI(uint256)", [i]],
                        "tokenURI(uint256) returns (string)"
                    );
                    calls.push(tUri);
                }




                const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();

                /* console.log("======== ZOOM CALL START ============");
                console.time("zoomCall_punks"); */
                const combinedResult = await zoom2.combine(ZoomQueryBinary);
                /* console.timeEnd("zoomCall_punks");
                console.log("======== ZOOM CALL END =============="); */

                ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);

                for (let i = 0; i < np * 2; i = i + 2) {
                    const id = ZoomLibraryInstance.decodeCall(calls[i]).toString();
                    //console.log('TOKEN ID PUNK', id);
                    const tokenURI = ZoomLibraryInstance.decodeCall(calls[i + 1]).toString();
                    punkTokens.push({ id, tokenURI });
                }

            }


            //meebits
            if (nm > 0) {
                ZoomLibraryInstance = new Zoom({ use_reference_calls: true });
                let calls = [];
                for (let i = 0; i < nm; i++) {
                    // request the token ID
                    const tId = ZoomLibraryInstance.addMappingCountCall(
                        // the contract we're calling
                        meebitContract,
                        ["tokenOfOwnerByIndex", [address, i]],
                        "tokenOfOwnerByIndex(address,uint256) returns (uint256)",
                        [{ contract: meebitContract, mapAndParams: ["tokenURI(uint256)", [i]] }]
                    );
                    calls.push(tId);

                    const tUri = ZoomLibraryInstance.addType5Call(
                        meebitContract,
                        ["tokenURI(uint256)", [i]],
                        "tokenURI(uint256) returns (string)"
                    );
                    calls.push(tUri);
                }

                const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();

                /* console.log("======== ZOOM CALL START ============");
                console.time("zoomCall_punks"); */
                const combinedResult = await zoom2.combine(ZoomQueryBinary);
                /* console.timeEnd("zoomCall_punks");
                console.log("======== ZOOM CALL END =============="); */

                ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);

                for (let i = 0; i < nm * 2; i = i + 2) {
                    const id = ZoomLibraryInstance.decodeCall(calls[i]).toString();
                    //console.log('TOKEN ID PUNK', id);
                    const tokenURI = ZoomLibraryInstance.decodeCall(calls[i + 1]).toString();
                    meebitTokens.push({ id, tokenURI });
                }

            }


            //tysons
            if (nty > 0) {
                ZoomLibraryInstance = new Zoom({ use_reference_calls: true });
                let calls = [];
                for (let i = 0; i < nty; i++) {
                    // request the token ID
                    const tId = ZoomLibraryInstance.addMappingCountCall(
                        // the contract we're calling
                        tysonContract,
                        ["tokenOfOwnerByIndex", [address, i]],
                        "tokenOfOwnerByIndex(address,uint256) returns (uint256)",
                        [{ contract: tysonContract, mapAndParams: ["tokenURI(uint256)", [i]] }]
                    );
                    calls.push(tId);

                    const tUri = ZoomLibraryInstance.addType5Call(
                        tysonContract,
                        ["tokenURI(uint256)", [i]],
                        "tokenURI(uint256) returns (string)"
                    );
                    calls.push(tUri);
                }

                const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();

                /* console.log("======== ZOOM CALL START ============");
                console.time("zoomCall_punks"); */
                const combinedResult = await zoom2.combine(ZoomQueryBinary);
                /* console.timeEnd("zoomCall_punks");
                console.log("======== ZOOM CALL END =============="); */

                ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);

                for (let i = 0; i < nty * 2; i = i + 2) {
                    const id = ZoomLibraryInstance.decodeCall(calls[i]).toString();
                    //console.log('TOKEN ID PUNK', id);
                    const tokenURI = ZoomLibraryInstance.decodeCall(calls[i + 1]).toString();
                    tysonTokens.push({ id, tokenURI });
                }

            }





            console.log('EC', ecTokens);
            console.log('Punks', punkTokens);
            console.log('Meebits', meebitTokens);
            console.log('Tysons', tysonTokens);


            ecTokens.sort((a, b) => {
                if (Number(a.id) < Number(b.id)) {
                    return -1;
                }
                if (Number(a.id) > Number(b.id)) {
                    return 1;
                }
                return 0;
            });


            setCards(ecTokens);
            setThePunks(punkTokens);
            setTheMeebits(meebitTokens);
            setTheTysons(tysonTokens);

        } else {
            setCards([]);
            setThePunks([]);
        }
        setIsLoading(false);
    }

    const handleClick = (idx) => {
        //console.log('click', idx);
        setSelectedIdx(idx);
    }


    const handlePrevNextClick = (value) => {



        setSelectedIdx(selectedIdx + value);

        props.history.push('/wallet/show/' + (selectedIdx + value));


    }


    const showTncAndClaim = (claimData) => {
        if (claimData && countryBlocked) {
            setShowRestricted(true)
        } else {
            setShowTnc(claimData || true);
        }
    }

    const fetchUserCountry = async () => {
        const res = await axios.get(`${baseServer}/api/location`).catch(e => console.log(e));
        if (res && res.data && res.data.country_code) {
            const countryCode = res.data.country_code;
            setUserCountryCode(countryCode);
        } else {
            toast.error('Unable to determine your country')
        }
    }


    return (
        <>
            <ECNav projectUrl={config.APP_BASE_URL} onboard={onboard} address={address} />

            <div id="walletContainer" className="container">
                <div className="row padd">
                    <div className="col-lg-12 my-5 text-center">

                        <h2 className="text-center">Welcome to your wallet</h2>

                        {!isConnected && <div className="row">
                            <div className="col-md-8 mx-auto text-center mb-3 mt-5">

                                <p>Just like a cryptocurrency wallet, it will allow you to receive and store your first Ether Cards.</p>

                                <p>In the future, you'll also be able to use this wallet to send, receive and trade Ether Cards with other community members. For now, it will serve as a repository for your collection and as your own personal gallery, where you can view the Cards' art.</p>

                                <p>You can find your wallet address in the top right corner of this page.</p>
                            </div>
                        </div>
                        }

                        {isConnected && <div className="row">
                            <div className="col-md-8 mx-auto text-center mb-3 mt-5">
                                <p>Here you can view and interact with your NFTs. Ether Cards NFTs have utility traits.</p>
                                <p>Click on the traits icon and check them out.</p>
                            </div>
                        </div>
                        }

                        {isConnected && !isLoading && <GiftWithdrawal setGiftIds={setGiftIds} tokenIds={tokenIds} showTnc={showTncAndClaim} />}
                        {/* {isConnected && !isLoading && <GeneWithdrawal tokenIds={tokenIds} showTnc={showTncAndClaim} />} */}
                        {isConnected && !isLoading && <DustWithdrawal tokenIds={tokenIds} showTnc={showTncAndClaim} />}

                        {isConnected ?
                            <div className="row">
                                {isLoading ?
                                    <div className="col-lg-12 mt-5 mb-5 text-center">
                                        <SpinnerDotted enabled={isLoading} size={35} thickness={160} speed={200} color="#fff" />
                                    </div>
                                    :
                                    <>
                                        <div className="col-12 text-center mt-5">
                                            <Title>Cards in your wallet</Title>
                                        </div>

                                        {cards.map((key, i) => {
                                            return (
                                                <div key={cards[i].id} className="col-md-3 mx-auto mt-5">
                                                    <WalletCard key={'i' + i} index={i} metaUrl={key.tokenURI} handleClick={handleClick} hasGift={giftIds.includes(Number(cards[i].id))} />
                                                    <p className="text-center">#{cards[i].id.toString()}</p>
                                                </div>
                                            );
                                        })}

                                        {(thePunks.length > 0 || theMeebits.length > 0) &&
                                            <div className="col-md-12">
                                                <div className="row">

                                                    {thePunks.map((key, i) => {
                                                        return (
                                                            <div key={thePunks[i].id} className="col-md-3 mx-auto mt-5">
                                                                <WalletPunkCard key={'i' + i} index={i} metaUrl={key.tokenURI} handleClick={null} />
                                                                <p className="text-center">#{thePunks[i].id.toString()}</p>
                                                            </div>
                                                        );
                                                    })}

                                                    {theMeebits.map((key, i) => {
                                                        return (
                                                            <div key={theMeebits[i].id} className="col-md-3 mx-auto mt-5">
                                                                <WalletMeebitCard key={'i' + i} index={i} metaUrl={key.tokenURI} handleClick={null} />
                                                                <p className="text-center">#{theMeebits[i].id.toString()}</p>
                                                            </div>
                                                        );
                                                    })}

                                                    {theTysons.map((key, i) => {
                                                        return (
                                                            <div key={theTysons[i].id} className="col-md-3 mx-auto mt-5">
                                                                <WalletMeebitCard key={'i' + i} index={i} metaUrl={key.tokenURI} handleClick={null} />
                                                                <p className="text-center">#{theTysons[i].id.toString()}</p>
                                                            </div>
                                                        );
                                                    })}

                                                </div>
                                            </div>
                                        }


                                        {cards.length === 0 &&
                                            <div className="col-md-6 mx-auto mt-5 text-center">
                                                <h5>Your wallet is empty.</h5>
                                            </div>
                                        }
                                    </>
                                }
                            </div>

                            :

                            <div className="row">
                                <div className="col-md-4 mx-auto mt-5 text-center">
                                    <h5 className="mb-5">In order to see your tokens, you need to connect your wallet</h5>

                                    <PinkOutline onClick={handleConnect}>CONNECT</PinkOutline>
                                </div>
                            </div>
                        }
                    </div>

                </div>



            </div>



            {showExplorer &&
                <Explorer
                    visible={true}
                    tokenId={tokenId}
                    serie='founder'
                    setSize={cards.length}
                    useIndex={true}
                    currentIdx={selectedIdx}
                    onClose={() => { setShowExplorer(false); setSelectedIdx(null) }}
                    onNavigateClick={handlePrevNextClick}
                    showTncAndClaim={showTncAndClaim}
                />}

            <DustTnc showTnc={showTnc} hideTnc={() => setShowTnc(null)} />
            <RestrictedCountries show={showRestricted} hide={() => setShowRestricted(false)}></RestrictedCountries>
        </>
    );
}

export default WalletPage;